import React, { useState, useEffect } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Help from "./Help";
import Image from "../Image";
import { usePermissions } from "../../contexts/PermissionsContext";
import { useAuth } from "../../contexts/AuthContext";

const Sidebar = ({ className, onClose }) => {
  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);
  const [navigation, setNavigation] = useState([]);
  const { pathname } = useLocation();
  const { permissions } = usePermissions();
  const { artifacts } = useAuth();
  const baseNavigation = [
    {
      title: "Home",
      icon: "home",
      url: "/",
      sideBarPath: "/Home",
    },
  ];

  const buildDropdown = (itemsByType, title, icon) => ({
    title,
    icon,
    dropdown: Object.keys(itemsByType).map((type) => ({
      title: type.charAt(0).toUpperCase() + type.slice(1) + "s",
      icon: { character: "diamond" }[type], // Adjust the icon mapping as needed
      dropdown: itemsByType[type],
    })),
    sideBarPath: `/${title}`,
  });

  useEffect(() => {
    const initNavigation = () => {
      const assetsByType = {};
      const offersByType = [];
      const featuresByType = [];

      if (artifacts) {
        artifacts
          .filter(
            (artifact) => artifact.display_name?.toLowerCase() !== "decoy"
          )
          .forEach((artifact) => {
            const displayName = artifact.display_name.toLowerCase();

            if (
              artifact.type === "template" &&
              permissions?.hasOwnProperty(displayName) &&
              permissions[displayName] === true
            ) {
              offersByType.push({
                title: artifact.display_name,
                url: `/${displayName.replace(/ /g, "-")}`,
              });
            } else if (
              artifact.type === "feature" &&
              permissions?.hasOwnProperty(displayName) &&
              permissions[displayName] === true
            ) {
              featuresByType.push({
                title: artifact.display_name,
                url: `/${displayName.replace(/ /g, "-")}/${
                  artifact.artifact_id
                }`,
              });
            } else if (
              artifact.type !== "feature" &&
              artifact.type !== "component"
            ) {
              if (!assetsByType[artifact.type]) {
                assetsByType[artifact.type] = [];
              }
              assetsByType[artifact.type].push({
                title: artifact.display_name,
                url: `/artifacts/${artifact.artifact_id}`,
              });
            }
          });
      }

      const assetsDropdown = buildDropdown(assetsByType, "Assets", "lightning");

      const galleryDropdown = {
        title: "Gallery",
        sideBarPath: "/Gallery",
        icon: "store",
        dropdown: [
          {
            title: "Assets",
            dropdown: Object.keys(assetsByType).map((type) => ({
              title: type.charAt(0).toUpperCase() + type.slice(1) + "s",
              url: `/gallery/${type.toLowerCase()}`,
            })),
            dataTestId: "GalleryDropdownItem-Assets",
          },
          {
            title: "Offers",
            dropdown: offersByType.map((offer) => ({
              ...offer,
              url: `/gallery/${offer.title.toLowerCase().replace(/ /g, "-")}`,
            })),
            dataTestId: "GalleryDropdownItem-Offers",
          },
        ].filter((category) => category.dropdown.length > 0),
      };

      let updatedNavigation = [baseNavigation[0]]; // Home

      if (assetsDropdown.dropdown.length > 0) {
        updatedNavigation.push(assetsDropdown);
      }

      if (offersByType.length > 0) {
        updatedNavigation.push({
          title: "Offers",
          icon: "promotion",
          dropdown: offersByType,
          sideBarPath: "/Offers",
        });
      }

      if (featuresByType.length > 0) {
        updatedNavigation.push({
          title: "Features",
          icon: "multiselect",
          dropdown: featuresByType,
          sideBarPath: "/Features",
        });
      }

      if (galleryDropdown.dropdown.length > 0) {
        updatedNavigation.push(galleryDropdown);
      }

      setNavigation(updatedNavigation);
    };

    initNavigation();
  }, [artifacts, permissions]);

  return (
    <>
      <div
        className={cn(styles.sidebar, className, { [styles.active]: visible })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link className={styles.logo} to="/" onClick={onClose}>
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link>
        <div className={styles.menu}>
          {navigation.map((DropDownItem, index) =>
            DropDownItem.url ? (
              <NavLink
                className={cn(styles.item, {
                  [styles.active]: pathname === DropDownItem.url,
                })}
                to={DropDownItem.url}
                key={index}
                onClick={onClose}
                data-testid={"DropDownItem: " + DropDownItem.title}
              >
                {DropDownItem.icon && (
                  <Icon name={DropDownItem.icon} size="24" />
                )}
                {DropDownItem.iconSrc && (
                  <div style={{ marginLeft: 1, paddingRight: 12 }}>
                    <Icon
                      src={DropDownItem.iconSrc}
                      size={DropDownItem.iconSize}
                    />
                  </div>
                )}
                {DropDownItem.title}
              </NavLink>
            ) : (
              <Dropdown
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={DropDownItem}
                onClose={onClose}
              />
            )
          )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      />
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
