import React from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";

import PromptBox from "../../../components/PromptBox";



const Profile = ({artifact, generateCreations: generateCreations, canGenerate:canGenerate}) => {
  return (
    <div className={styles.profile} style={{textAlign:"center"}}>
      <div className={styles.details} style={{display:"flex", justifyContent: "center"}}>
        <div className={styles.wrap}>
          <div className={cn("h4", styles.man)}>{artifact.display_name}</div>
          <PromptBox artifact={artifact} OnGenerateClicked={generateCreations} canGenerate={canGenerate} isCharacter={true}/>
        </div>
      </div>
    </div>
  );
};

export default Profile;
