import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import styles from "./GenerateOfferScreen3.module.sass";
import { useLocation } from "react-router-dom";
import ApiService from "../../services/ApiService";
import Profile from "./Profile/";
import cn from "classnames";
import Icon from "../../components/Icon";
import { toast } from "react-hot-toast";
import ZipCreator from "../../services/ZipCreatorService";
import GenerationService from "../../services/GenerationService";
import { useAuth } from "../../contexts/AuthContext";
import CreationCard from "../../components/Product";
import { useParams } from "react-router-dom";
import { useModalPreview } from "../../contexts/ModalPreviewContext";

const GenerateOfferScreen3 = () => {
  const { openModal } = useModalPreview();
  const [creations, setCreations] = useState([]);
  const [paginatedCreations, setPaginatedCreations] = useState([]);
  const [filteredCreations, setFilteredCreations] = useState([]);
  const [generationErrorMessage, setGenerationErrorMessage] = useState("");
  const [selectedItems, setSelectedItems] = useState({
    "droppable-1": [{ is_place_holder: true }],
    "droppable-2": [{ is_place_holder: true }],
  });
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [artifact, setArtifact] = useState(null);
  const [subArtifacts, setSubArtifacts] = useState([]);
  const [contentVisible, setContentVisible] = useState(true);
  const location = useLocation();
  const [canGenerate, setCanGenerate] = useState(true);
  const [loading, setLoading] = useState(true);
  const isNotSimple = true;
  const { organizationData } = useAuth();
  const [baseCreation, setBaseCreation] = useState(
    location.state?.baseCreation
  );
  const [currentViewingItemIndex, setCurrentViewingItemIndex] = useState();
  const { artifactId, creationType } = useParams();
  const [selectedSubArtifact, setSelectedSubArtifact] = useState(null);
  const [selectedSubArtifactIndex, setSelectedSubArtifactIndex] = useState();

  useEffect(() => {
    setSelectedSubArtifact(subArtifacts[selectedSubArtifactIndex]);
  }, [selectedSubArtifactIndex]);

  const toggleSubArtifact = (index) => {
    setSelectedSubArtifactIndex(index);
  };

  const handleDownload = async () => {
    const files = [
      {
        name: `decoy_1.png`,
        url: selectedItems["droppable-1"][0].result_image_url[0],
      },
      {
        name: `decoy_2.png`,
        url: selectedItems["droppable-2"][0].result_image_url[0],
      },
    ];

    await ZipCreator.createZipFile(selectedItems, "Quest");

    try {
      ApiService.post(
        `/download_creation/${selectedItems[0].creation_id}/${selectedItems[0].creation_sub_id}`
      );
      ApiService.post(
        `/download_creation/${selectedItems[1].creation_id}/${selectedItems[1].creation_sub_id}`
      );
    } catch (error) {
      console.error("Failed to download:", error);
    }
  };

  const onDownload = async (creation) => {
    console.log(selectedItems[1]);
    console.log(selectedItems[1].creation_id);
    console.log(selectedItems[1].creation_sub_id);

    const files = Object.values(selectedItems)
      .flat()
      .filter((item) => item && item.result_image_url) // Filters out any empty or falsy items
      .map((item, index) => ({
        name: `${item.creation_id}_${item.creation_sub_id}.png`,
        url: item.result_image_url[0],
      }));

    console.log(files);
    toast
      .promise(ZipCreator.createZipFile(files, "quest"), {
        loading: "Downloading Offer...",
        success: "Downloading was successful!",
        error: "Failed to create zip file. Please try again later.",
      })
      .then(async () => {
        try {
          ApiService.post(
            `/download_creation/${creation.creation_id}/${creation.creation_sub_id}`
          );
        } catch (error) {
          console.error("Failed to toggle like status:", error);
        }
      })
      .catch((error) => {
        console.error("Error during zip creation or download:", error);
      });
  };

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const toggleLikeStatus = async (creationId, creationSubId) => {
    const index = creations.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id === creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }

    const updatedStatus = !creations[index].liked;
    const updatedCreations = creations.map((creation, i) => {
      if (i === index) {
        return { ...creation, liked: updatedStatus };
      }
      return creation;
    });

    toast
      .promise(
        ApiService.post(
          `/like_or_dislike_creation/${creationId}/${creationSubId}`,
          { liked: updatedStatus }
        ),
        {
          loading: "Updating like status...",
          success: () => {
            setCreations(updatedCreations);
            return updatedStatus
              ? "Liked successfully!"
              : "Unliked successfully!";
          },
          error: "Failed to update like status. Please try again later.",
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const regenerateCreation = async (creationId, creationSubId) => {
    const index = creations.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id === creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }
    await GenerationService.RegenerateCreation(creations[index]);
    fetchArtifactAndCreations();
  };

  const generateNewVariations = async (creationId, creationSubId) => {
    const index = creations.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id === creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }
    await GenerationService.GenerateNewVariations(creations[index]);
    fetchArtifactAndCreations();
  };

  const toggleOverviewVisibility = async (state, index) => {
    const filteredCreations = filterCreationsForDisplay(creations);
    const item = creations[index];
    const filteredIndex = filteredCreations.findIndex(
      (filteredItem) =>
        filteredItem.creation_id === item.creation_id &&
        filteredItem.creation_sub_id === item.creation_sub_id
    );
    setCurrentViewingItemIndex(filteredIndex);
  };

  const filterCreationsForDisplay = (creations) => {
    return creations.filter((item) => item.result_image_url);
  };

  useEffect(() => {
    if (artifactId) {
      fetchArtifactAndCreations();
    }
  }, [artifactId]);

  useEffect(() => {
    if (selectedSubArtifact) return;

    const newSelectedSubArtifactIndex = subArtifacts.findIndex(
      (subArtifact) => !subArtifact.hidden
    );
    setSelectedSubArtifactIndex(newSelectedSubArtifactIndex);
  }, [subArtifacts]);

  useEffect(() => {
    if (creations.length > 0) {
      const filtered = creations.filter(
        (creation) =>
          creation.artifact_id ===
          subArtifacts[selectedSubArtifactIndex]?.artifact_id
      );
      setFilteredCreations(filtered);
    }
  }, [selectedSubArtifactIndex, creations, subArtifacts]);

  const generateCreations = ({
    prompt,
    numImagesPerPrompt,
    isRemoveBackground,
    enrichPromptByChatGPTFlag,
    creationId = null,
    image,
    referenceType,
    dimensionsRatio,
    colorPalette,
    settings,
  }) => {
    const timestamp = Date.now();
    setCanGenerate(false);

    toast.promise(
      (async () => {
        const response = await GenerationService.startGeneration({
          prompt: prompt,
          numberOfImages: numImagesPerPrompt,
          isRemoveBackground,
          enrichPromptByChatGPTFlag,
          creationId,
          image,
          referenceType,
          dimensionsRatio,
          colorPalette,
          artifact: selectedSubArtifact,
          artifactId: selectedSubArtifact.artifact_id,
          settings,
        });

        if (response.error) {
          throw new Error(response.error);
        }

        await fetchArtifactAndCreations();
        return response;
      })(),
      {
        loading: "Generating creations...",
        success: () => {
          setCanGenerate(true);
          return "Creations generated successfully!";
        },
        error: (err) => {
          console.log(err);
          console.error("Failed to initiate generation:", err);
          setCreations((prevCreations) =>
            prevCreations.filter(
              (creation) =>
                !creation.creation_id.startsWith("temp_" + timestamp)
            )
          );
          setCanGenerate(true);
          return "Failed to initiate generation. Please try again later.";
        },
      }
    );
  };

  const fetchArtifactAndCreations = async (
    initialLoad = false,
    startKey = null
  ) => {
    if (initialLoad) {
      setLoading(true);
      setContentVisible(false);
    }
    let artifactResponse = null;
    let creationsResponse;
    console.log("Fetching data...");

    try {
      if (artifactId) {
        const artifactResponse = await ApiService.get(
          `/get_artifact/${artifactId}`
        );
        setArtifact(artifactResponse.data.artifact);
        setSubArtifacts(artifactResponse.data.sub_artifacts);
        const newSubArtifacts = artifactResponse.data.sub_artifacts;
        console.log("newSubArtifacts: ", newSubArtifacts);
        console.log("subArtifacts: ", subArtifacts);

        let fetchedCreations = [];

        for (const subArtifact of newSubArtifacts) {
          console.log(
            "Fetching creations for sub-artifact: ",
            subArtifact.artifact_id
          );
          console.log("artifact_id:", subArtifact.artifact_id);
          const creationsResponse = await ApiService.get(
            `/get_creations_based_on_artifact_id?artifact_id=${subArtifact.artifact_id}&limit=45`
          );
          console.log("Response: ", creationsResponse);
          fetchedCreations = fetchedCreations.concat(creationsResponse.data);
          console.log("Fetched Creations: ", fetchedCreations);
        }
        setCreations(fetchedCreations);
        setLastEvaluatedKey(artifactResponse.next_start_key);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      if (initialLoad) {
        setLoading(false);
      }
    }
  };

  // const convertImageToBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       resolve(reader.result.split(",")[1]);
  //     };
  //     reader.onerror = reject;
  //     reader.readAsDataURL(file);
  //   });
  // };

  const handleSelectItem = (creation, droppableId) => {
    setSelectedItems((prev) => ({
      ...prev,
      [droppableId]: creation,
    }));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    let paginatedCreations = [];
    if (filteredCreations.length > 0) {
      paginatedCreations = filteredCreations.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );
    }
    setPaginatedCreations(paginatedCreations);
  }, [filteredCreations, currentPage]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkStatusOfPendingCreations();
    }, 10000);

    return () => clearInterval(interval);
  }, [creations]);

  const checkStatusOfPendingCreations = async () => {
    const pendingCreations = creations?.filter(
      (creation) => creation.status === "pending"
    );
    if (!pendingCreations || pendingCreations.length === 0) {
      return;
    }

    const payload = pendingCreations.map((creation) => ({
      creation_id: creation.creation_id,
      creation_sub_id: creation.creation_sub_id,
    }));

    try {
      const response = await ApiService.post("/check_status", payload);
      console.log(response);
      const updatedCreations = response.results;

      setCreations((prevCreations) =>
        prevCreations.map((c) => {
          const updatedCreation = updatedCreations.find(
            (uc) =>
              uc.creation_id === c.creation_id &&
              uc.creation_sub_id === c.creation_sub_id
          );
          return updatedCreation && !updatedCreation.error
            ? { ...c, ...updatedCreation.data }
            : c;
        })
      );
    } catch (error) {
      console.error("Error checking status:", error);
    }
  };

  return (
    <div
      className={`${styles.shop} ${
        generationErrorMessage ? styles.fadeIn : ""
      }`}
    >
      <div className={styles.background}>
        <img src={organizationData?.app_bg} alt="Background" />
      </div>
      {selectedSubArtifact && (
        <>
          <Profile
            artifact={selectedSubArtifact}
            generateCreations={generateCreations}
            error={generationErrorMessage}
            canGenerate={canGenerate}
            baseCreation={baseCreation}
          />

          <Card className={styles.card}>
            <div className={styles.toggleContainer}>
              {subArtifacts.map((subArtifact, index) => (
                <div
                  key={index}
                  className={`${styles.toggleButton} ${
                    selectedSubArtifactIndex === index ? styles.active : ""
                  } ${subArtifact.hidden ? styles.disabled : ""}`}
                  onClick={() =>
                    !subArtifact.hidden && toggleSubArtifact(index)
                  }
                  style={{
                    pointerEvents: subArtifact.hidden ? "none" : "auto",
                  }}
                >
                  {/* {subArtifact.display_name || `Stage ${index + 1}`}{" "} */}
                  {subArtifact.display_name.includes("-")
                    ? subArtifact.display_name
                        .split("-")[1]
                        .trim()
                        .split(" ")[0]
                        .charAt(0)
                        .toUpperCase() +
                      subArtifact.display_name
                        .split("-")[1]
                        .trim()
                        .split(" ")[0]
                        .slice(1)
                    : subArtifact.display_name || `Stage ${index + 1}`}{" "}
                </div>
              ))}
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={styles.wrap}>
                <div
                  className={cn(styles.gallery, {
                    [styles.triple]: isNotSimple,
                  })}
                >
                  {paginatedCreations.map((creation, index) => (
                    <div key={index} className={styles.productWrapper}>
                      <CreationCard
                        item={creation}
                        showPrompt={false}
                        fixedSize={200}
                        onLikeDislike={() =>
                          toggleLikeStatus(
                            creation.creation_id,
                            creation.creation_sub_id
                          )
                        }
                        onRegenerateClick={
                          artifactId
                            ? () =>
                                regenerateCreation(
                                  creation.creation_id,
                                  creation.creation_sub_id
                                )
                            : null
                        }
                        onGenerateVariationsClick={
                          artifactId
                            ? () =>
                                generateNewVariations(
                                  creation.creation_id,
                                  creation.creation_sub_id
                                )
                            : null
                        }
                        // onSelectQuest={
                        //   artifactId
                        //     ? () =>
                        //         handleSelectItem(
                        //           creation,
                        //           selectedSubArtifactIndex
                        //         )
                        //     : null
                        // }
                        onShowPreview={() =>
                          openModal(
                            filteredCreations,
                            index,
                            fetchArtifactAndCreations
                          )
                        }
                      />
                      {creation.status === "completed" && (
                        <button
                          className={styles.chooseButton}
                          onClick={() =>
                            handleSelectItem(creation, selectedSubArtifactIndex)
                          }
                        >
                          Choose
                        </button>
                      )}
                    </div>
                  ))}
                </div>

                <div className={styles.bottomPanel}>
                  <div className={styles.pagination}>
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Newer
                    </button>
                    <span>{currentPage}</span>
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={
                        currentPage * itemsPerPage >= filteredCreations.length
                      }
                    >
                      Older
                    </button>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 15, marginRight: -20 }}>
                {subArtifacts.map((subArtifact, index) => (
                  <>
                    <div key={index} className={styles.pickedScreenContainer}>
                      {/* <div
                      className={cn(styles.backgroundIndicator, {
                        [styles.activeIndicator]:
                          selectedSubArtifactIndex === index,
                      })}
                    /> */}
                      <div
                        className={cn(styles.pickedScreen, {
                          // [styles.firstScreen]: index === 0,
                          // [styles.lastScreen]: index === subArtifacts.length - 1,
                          // [styles.middleScreen]:index > 0 && index < subArtifacts.length - 1,
                          // [styles.tournamentLeaderboard]: artifact.artifact_id === 'eaeee3c1-7897-4152-aef4-d9450719b198' && index == 0,
                        })}
                        style={{
                          backgroundImage:
                            artifact.artifact_id ===
                              "eaeee3c1-7897-4152-aef4-d9450719b198" &&
                            index == 0
                              ? null
                              : selectedItems[index]?.result_image_url
                              ? `url(${selectedItems[index].result_image_url})`
                              : subArtifact.hidden
                              ? `url(${process.env.PUBLIC_URL}/images/coming_soon.png)`
                              : null,
                        }}
                      >
                        {!subArtifact.hidden &&
                          (artifact.artifact_id ===
                          "9edb962b-d689-4af5-8a46-dddae79f7a59" ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/images/items_only.png`}
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                zIndex: 2,
                                pointerEvents: "none",
                              }}
                            />
                          ) : artifact.artifact_id ===
                            "eaeee3c1-7897-4152-aef4-d9450719b198" ? (
                            index == 1 ? (
                              <img
                                src={`${process.env.PUBLIC_URL}/images/tournamentFull.png`}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  zIndex: 2,
                                  pointerEvents: "none",
                                }}
                              />
                            ) : (
                              <>
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/tournamentLeaderboard.png`}
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 4,
                                    pointerEvents: "none",
                                  }}
                                />
                                <div
                                  style={{
                                    position: "absolute", // Position it absolutely within the parent div
                                    top: -101, // Align with the top of the parent div
                                    left: 0, // Align with the left of the parent div
                                    width: "100%", // Same width as the parent div
                                    height: "calc(100% - 10px)", // Adjust this value based on the percentage of the parent div's height you want to cover
                                    backgroundImage: selectedItems[index]
                                      ?.result_image_url
                                      ? `url(${selectedItems[index].result_image_url})`
                                      : null,
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    zIndex: 1,
                                  }}
                                />
                                {/* <img
                        src={selectedItems[index]?.result_image_url
                          ? `url(${selectedItems[index].result_image_url})`
                          : subArtifact.hidden}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          zIndex: 2,
                          pointerEvents: "none",
                        }}
                      /> */}
                              </>
                            )
                          ) : null)}
                      </div>
                      <div
                        className={cn(styles.backgroundIndicator, {
                          [styles.activeIndicatorTopTournament]:
                            artifact.artifact_id ===
                              "eaeee3c1-7897-4152-aef4-d9450719b198" &&
                            index == 0,
                          [styles.activeIndicator]:
                            selectedSubArtifactIndex === index,
                        })}
                        style={{
                          position: "absolute", // Ensure this is absolute
                          top: 0, // Align with the top of the container
                          left: "107%",
                          transform: "translateX(-100%)",
                        }}
                      />
                    </div>
                  </>
                ))}
                <center>
                  <div
                    className={cn("button button-small", styles.button)}
                    style={{ marginTop: 20 }}
                    // onClick={handleDownload}
                    onClick={onDownload}
                  >
                    <Icon name="download" size="24" />
                    <span
                      className={styles.download}
                      style={{
                        pointerEvents: "auto",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      Download Now
                    </span>
                  </div>
                </center>
              </div>
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default GenerateOfferScreen3;
