import React, { createContext, useContext, useState, useEffect } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig/authConfig";
import ApiService from "../services/ApiService";
import { usePermissions } from "./PermissionsContext";
import { toast } from "react-hot-toast";
import { useLoadingBar } from "./LoadingBarContext";

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();

export const AuthProvider = ({ children }) => {
  const { progress, setProgress } = useLoadingBar();
  const [currentUser, setCurrentUser] = useState(() => {
    const userFromStorage = localStorage.getItem("currentUser");
    return userFromStorage ? JSON.parse(userFromStorage) : null;
  });
  const { setPermissions } = usePermissions();
  const [artifacts, setArtifacts] = useState([]);
  const [organizationData, setOrganizationData] = useState({});

  function groupByType(artifacts) {
    return artifacts.reduce((acc, artifact) => {
      if (!acc[artifact.type]) {
        acc[artifact.type] = [];
      }
      acc[artifact.type].push(artifact);
      return acc;
    }, {});
  }

  const getArtifacts = async () => {
    try {
      const response = await ApiService.get("/get_artifacts");
      setArtifacts(response.data);
      return response.data;
    } catch (error) {
      toast.error("Failed to get artifacts");
      console.error(error);
      throw error;
    }
  };

  const getOrganizationData = async () => {
    try {
      const response = await ApiService.get("/get_organization_data");
      setOrganizationData(response);
      setPermissions(response.feature_flags_policy);
      return response.data;
    } catch (error) {
      toast.error("Failed to get organization data");
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    const accounts = msalInstance.getAllAccounts();
    if (currentUser && currentUser.azureAccount) {
      msalInstance.setActiveAccount(currentUser.azureAccount);
    } else if (accounts && accounts.length > 0) {
      setCurrentUser({ azureAccount: accounts[0], method: "azure" });
      localStorage.setItem(
        "currentUser",
        JSON.stringify({ azureAccount: accounts[0], method: "azure" })
      );
    }

    // Call getArtifacts and getOrganizationData on component mount (refresh)
    const fetchData = async () => {
      if (currentUser) {
        try {
          setProgress(20);
          await getOrganizationData();
          setProgress(40);
          await getArtifacts();
          setProgress(100);
        } catch (error) {
          console.error("Failed to fetch user data:", error);
        }
      }
    };
    fetchData();

    // Retrieve and set the access token from msalInstance on page load
    const retrieveAccessToken = async () => {
      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts && accounts.length > 0) {
          const silentRequest = {
            scopes: ["user.read"],
            account: accounts[0],
          };
          const response = await msalInstance.acquireTokenSilent(silentRequest);
          ApiService.setAccessToken(response.accessToken);
        }
      } catch (error) {
        console.error("Failed to acquire token silently: ", error);
        if (error instanceof msalInstance.InteractionRequiredAuthError) {
          await msalInstance.acquireTokenPopup({ scopes: ["user.read"] });
        }
      }
    };

    retrieveAccessToken();
  }, [currentUser]);

  const sendResetCode = async (email) => {
    try {
      const response = await ApiService.post("/forgot-password", {
        email: email,
      });
      console.log(response);

      return response.data;
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  };

  const resetPassword = async (email, newPassword, token) => {
    try {
      const response = await ApiService.post("/change-password", {
        email: email,
        new_password: newPassword,
        token: token,
      });
      console.log(response);

      return response.data;
    } catch (error) {
      toast.error("Failed to reset password");
      console.error("Login failed:", error);
      throw error;
    }
  };

  const signInWithAD = async () => {
    try {
      setProgress(20);
      const request = { scopes: ["user.read"] };
      const loginResponse = await msalInstance.loginPopup(request);
      setCurrentUser({ azureAccount: loginResponse.account, method: "azure" });
      localStorage.setItem(
        "currentUser",
        JSON.stringify({ azureAccount: loginResponse.account, method: "azure" })
      );

      ApiService.setAccessToken(loginResponse.accessToken);
      setProgress(60);
      console.log(await getOrganizationData());

      await getArtifacts();

      toast.success("Logged in with Azure AD account successfully");
      return loginResponse.account;
    } catch (error) {
      toast.error("Failed to login with Azure AD account");
      console.error("AD login failed:", error);
      throw error;
    } finally {
      setProgress(100);
    }
  };

  const signUp = async (email, password) => {
    try {
      // Use ApiService to post registration data
      const data = await ApiService.post("/register", { email, password });
      console.log(data);
      toast.success("User registered successfully");
      console.log("User registered successfully", data);
    } catch (error) {
      console.error("Registration failed", error);
      throw error; // Propagate the error to be handled where login is called
    }
  };

  const signIn = async (username, password, organizaitonKey) => {
    console.log(username, password, organizaitonKey);
    try {
      setProgress(20);
      const response = await ApiService.post("/login", {
        username,
        password,
        organization_key: organizaitonKey,
      });
      console.log(response);
      setCurrentUser({ username: username, method: "username/password" });
      localStorage.setItem(
        "currentUser",
        JSON.stringify({ username: username, method: "username/password" })
      );
      ApiService.setAccessToken(response.AccessToken);
      ApiService.setRefreshToken(response.RefreshToken);
      localStorage.setItem("darkMode", false);
      await getArtifacts();
      setProgress(60);
      await getOrganizationData();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setProgress(100);
    }
  };

  const logout = () => {
    if (currentUser && currentUser.method === "azure") {
      msalInstance
        .logoutPopup()
        .then(() => {
          // This block will only execute after successful Azure logout
          console.log("Logged out from Azure AD");
          // Perform cleanup after successful logout from Azure AD
          localStorage.removeItem("currentUser");
          setCurrentUser(null);
          localStorage.removeItem("artifacts");
          console.log("Local storage cleared and user state reset.");
        })
        .catch((error) => {
          // Handle errors from Azure AD logout
          toast.error("Failed to logout from Azure AD");
          console.error(error);
        });
    } else {
      // For username/password logins, just clear the local storage and reset user state
      localStorage.clear();
      localStorage.setItem("darkMode", false);

      setCurrentUser(null);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        signIn,
        signInWithAD,
        logout,
        signUp,
        resetPassword,
        sendResetCode,
        artifacts,
        organizationData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
