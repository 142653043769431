import React from "react";
import { useModalPreview } from "../../contexts/ModalPreviewContext";
import CreationCard from "../Product";
import collectiblesDict from "./CollectiblesDict.js";
import { str } from "ajv";

const CollectiblesMatrix = ({
  creations,
  inputs,
  toggleLikeStatus,
  regenerateCreation,
  settings,
}) => {
  const { openModal } = useModalPreview();

  const handleRegenerateClick = (x, y) => {
    regenerateCreation(x, y);
  };

  const renderMatrix = () => {
    const renderContent = creations.map((creation, index) => {
      const x = Math.floor(index / 3); // Calculate row index (x)
      const y = index % 3; // Calculate column index (y)

      return (
        <div
          key={index}
          className={settings.itemStyle}
          style={settings.gridArea ? { gridArea: `item${index + 1}` } : {}}
        >
          <div
            style={{
              position: "relative",
              height: settings.height,
              width: settings.width,
              borderRadius: settings.borderRadius || "0px",
            }}
          >
            <CreationCard
              // className={styles.product}
              item={creation}
              key={index}
              onShowPreview={
                creation ? () => openModal(creations, index) : undefined
              }
              onLikeDislike={
                creation
                  ? () =>
                      toggleLikeStatus(
                        creation.creation_id,
                        creation.creation_sub_id
                      )
                  : undefined
              }
              onRegenerateClick={
                creation ? () => handleRegenerateClick(x, y) : undefined
              }
              showPrompt={false}
            />
            <img
              src={settings.frameImage}
              height={settings.height + settings.framePadding}
              width={settings.width + settings.framePadding}
              style={{
                position: "absolute",
                top: settings.frameTop,
                left: settings.frameLeft,
                zIndex: 100,
                pointerEvents: "none",
              }}
            />
            <div
              style={{
                position: "absolute",
                bottom: settings.promptTextBottom,
                width: "100%",
                textAlign: "center",
                color: "white",
                fontSize: settings.fontSize,
                zIndex: 101,
              }}
            >
              {inputs[index]?.substring(0, 12)}
            </div>
          </div>
        </div>
      );
    });

    const containerStyle = settings.containerStyle;
    const backgroundImage = settings.backgroundImage
      ? { backgroundImage: `url(${settings.backgroundImage})` }
      : {};

    return (
      <div style={{ ...containerStyle, ...backgroundImage }}>
        {renderContent}
      </div>
    );
  };

  return renderMatrix();
};

export default CollectiblesMatrix;

//   const companyConfig = collectiblesDict[organizationName.toLowerCase()]

//   return

//   const CreationCardWrapper = ({ creation, index, x, y }) => {
//     return (
//       <div
//         style={{
//           position: "relative",
//           height: "217.6px",
//           width: "170px",
//         }}
//       >
//         <CreationCard
//           className={styles.product}
//           item={creation}
//           key={index}
//           onShowPreview={creation ? () => openModal(creations, index) : undefined}
//           onLikeDislike={
//             creation
//               ? () => toggleLikeStatus(creation.creation_id, creation.creation_sub_id)
//               : undefined
//           }
//           onRegenerateClick={creation ? () => handleRegenerateClick(x, y) : undefined}
//           showPrompt={false}
//         />
//         <img
//         src={`${process.env.PUBLIC_URL}/images/collectiblesFrame.png`}
//         width={170}
//         height={180}
//         style={{
//           position: "absolute",
//           top: -33,
//           left: 0,
//           zIndex: 2,
//           pointerEvents: "none",
//         }}
//       />
//       <div
//         style={{
//           position: "absolute",
//           bottom: 57,
//           width: "100%",
//           textAlign: "center",
//           color: "white",
//           zIndex: 3,
//         }}
//       >
//         {inputs[index]?.substring(0, 12)}
//       </div>
//     </div>
//   );
// };

//   const renderMatrixInnplay = () => {
//     const renderContent = creations.map((creation, index) => {
//       const x = Math.floor(index / 3); // Calculate row index (x)
//       const y = index % 3; // Calculate column index (y)

//       return (
//         <div
//           key={index}
//           className={styles.imageWrapperInnplay}
//           style={{ gridArea: `item${index + 1}` }}
//         >
//           <CreationCardWrapper creation={creation} index={index} x={x} y={y} />
//         </div>
//       );
//     });

//     return <div className={styles.matrixContainerInnplay}>{renderContent}</div>;
//   };

//   const renderMatrixCommunix = () => {
//     const renderContent = creations.map((creation, index) => {
//       const x = Math.floor(index / 3); // Calculate row index (x)
//       const y = index % 3; // Calculate column index (y)

//       return (
//         <div key={index} className={styles.imageWrapperCommunix}>
//           {creation ? (
//             <div
//               style={{
//                 position: "relative",
//                 height: "164px",
//                 width: "104px",
//                 borderRadius: 10,
//               }}
//             >
//               <CreationCard
//                 className={styles.product}
//                 item={creation}
//                 key={index}
//                 onShowPreview={() => openModal(creations, index)}
//                 onLikeDislike={() =>
//                   toggleLikeStatus(
//                     creation.creation_id,
//                     creation.creation_sub_id
//                   )
//                 }
//                 onRegenerateClick={() => handleRegenerateClick(x, y)}
//                 showPrompt={false}
//               />
//               <img
//                 src={`${process.env.PUBLIC_URL}/images/communix_frame.png`}
//                 height={168}
//                 width={104}
//                 style={{
//                   position: "absolute",
//                   top: 0,
//                   left: 0,
//                   zIndex: 100,
//                   pointerEvents: "none",
//                 }}
//               />
//             </div>
//           ) : (
//             <div
//               style={{
//                 position: "relative",
//                 height: "164px",
//                 width: "104px",
//                 borderRadius: 10,
//               }}
//             >
//               <CreationCard
//                 className={styles.product}
//                 item={null}
//                 key={index}
//                 showPrompt={false}
//               />
//               <img
//                 src={`${process.env.PUBLIC_URL}/images/communix_frame.png`}
//                 height={168}
//                 width={104}
//                 style={{
//                   position: "absolute",
//                   top: 0,
//                   left: 0,
//                   zIndex: 100,
//                   pointerEvents: "none",
//                 }}
//               />
//             </div>
//           )}
//         </div>
//       );
//     });

//     return (
//       <div
//         className={styles.matrixContainerCommunix}
//         style={{
//           backgroundImage: `url(${process.env.PUBLIC_URL}/images/communix_collectibles_background.png)`,
//         }}
//       >
//         {renderContent}
//       </div>
//     );
//   };

//   if (organizationName === "COMMUNIX-418be7") {
//     return <>{renderMatrixCommunix()}</>;
//   }

//   return <>{renderMatrixInnplay()}</>;
// };

// export default CollectiblesMatrix;
