import { downloadImage, updateDownloaded } from "../utils";
import ApiService from "./ApiService";
import ZipCreator from "./ZipCreatorService";
export const DownloadService = {
  DownloadCreation: async (creation) => {
    if (creation.result_image_url.length === 1) {
      let currentImageUrl = creation.result_image_url[0];
      await updateDownloaded(creation.creation_id, creation.creation_sub_id);
      downloadImage(currentImageUrl, "image.png");
    } else {
      await DownloadService.DownloadComplexCreation(creation);
    }
  },
  DownloadComplexCreation: async (creation) => {
    const files = [
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_active.png`,
        url: creation.components_details.active_passive.result_image_url[0],
      },
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_passive.png`,
        url: creation.components_details.active_passive.result_image_url[1],
      },
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_title.png`,
        url: creation.components_details.title.result_image_url[0],
      },
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_title1.png`,
        url: creation.components_details.title.result_image_url[1],
      },
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_title2.png`,
        url: creation.components_details.title.result_image_url[2],
      },
    ];
    console.log(files);
    await ZipCreator.createZipFile(
      files,
      creation.creation_id + "_" + creation.creation_sub_id
    );

    try {
      await ApiService.post(
        `/download_creation/${creation.creation_id}/${creation.creation_sub_id}`
      );
    } catch (error) {
      console.error("Failed to toggle like status:", error);
    }
  },
  DownloadCreationX2: async (creation) => {
    let currentImageUrl = creation.result_image_url[0];
    await updateDownloaded(creation.creation_id, creation.creation_sub_id);
    const result = await DownloadService.UpscaleImage(currentImageUrl);
    downloadImage(result["result"][0], "image2.pngx2");
  },

  UpscaleImage: async (image_url) => {
    const data_input = { image_url };
    return await ApiService.post(`/get_upscaled_image`, data_input);
  },
};


// toast.promise(
//   (async () => {
//     const response = await ApiService.post('/start_generation', requestData);
//     await fetchArtifactAndProducts();
//     if (response.error) {
//       throw new Error(response.error);
//     }
//     setTimeout(() => {
//       fetchArtifactAndProducts();
//     }, 40000);
//     return response;
//   })(),
//   {
//     loading: 'Generating creations...',
//     success: () => {
//       setCanGenerate(true);
//       return 'Creations generated successfully!';
//     },
//     error: (error) => {
//       console.error('Failed to initiate generation:', error);
//       setGenerationErrorMessage('Failed to initiate creation generation. Please try again later.');
//       setCreations(prevCreations => prevCreations.filter(creation => !creation.creation_id.startsWith("temp_" + timestamp)));
//       setCanGenerate(true);
//       return 'Failed to generate creations. Please try again later.';
//     },
//   }
// );
