// SignUp.js
import React, { useState } from "react";
import cn from "classnames";
import styles from "../SignIn/SignIn.module.sass"
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";

import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-hot-toast";
import { validatePassword } from "../../utils.js";


const SignUp = () => {
  const heightWindow = use100vh();
  const { signUp } = useAuth(); // Adjusted for the sign-up function

  // State for input fields and managing loading/error states
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // Added field for password confirmation
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    if(!validatePassword(password)){
      return
    }
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    setIsLoading(true);
    try {
      await signUp(email, password); // Assuming signUp function can handle these
      navigate("/")
    } catch (error) {
      toast.error('Sign Up failed: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link>
        <div className={cn("h2", styles.title)}>Sign up</div>
        <form onSubmit={handleSignUp}>

          <TextInput
            className={styles.field}
            name="email"
            type="email"
            placeholder="Your email"
            required
            icon="mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextInput
            className={styles.field}
            name="password"
            type="password"
            placeholder="Password"
            required
            icon="lock"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextInput
            className={styles.field}
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            required
            icon="lock"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button className={cn("button", styles.button)} disabled={isLoading}>Sign up</button>
       </form>
        <div className={styles.info}>
          Already have an account?{" "}
          <Link className={styles.link} to="/sign-in">
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
