import React, { useState } from "react";
import cn from "classnames";
import styles from "./Dropdown.module.sass";
import { NavLink, useLocation } from "react-router-dom";
import Icon from "../../Icon";

const Dropdown = ({ className, item, visibleSidebar, onClose }) => {
  const [visible, setVisible] = useState(false);
  const [activeIndexes, setActiveIndexes] = useState({});
  const { pathname } = useLocation();

  const handleClick = () => {
    setVisible(!visible);
  };

  const handleSubmenuClick = (index) => {
    setActiveIndexes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const Head = ({ title, icon, isActive, onClick, dataTestId }) => (
    <button
      className={cn(
        styles.head,
        { [styles.active]: isActive },
        { [styles.wide]: visibleSidebar }
      )}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <Icon name={icon} size="24" />
      {title}
      <Icon name="arrow-s" size="24" />
    </button>
  );

  const renderDropdownItems = (
    items,
    level = 0,
    parentIndex = "",
    sideBarPath
  ) => {
    return items.map((DropDownItem, index) => {
      const currentIndex = `${parentIndex}-${index}`;
      const isSubmenuActive = activeIndexes[currentIndex];
      const hasDropdown = Array.isArray(DropDownItem.dropdown);

      return (
        <div
          key={currentIndex}
          className={cn(styles.dropdownItem, styles[`level-${level}`])}
        >
          {hasDropdown ? (
            <>
              <Head
                title={DropDownItem.title}
                level={level}
                icon={isSubmenuActive ? "arrow-down" : "arrow-next"}
                isActive={isSubmenuActive}
                onClick={() => handleSubmenuClick(currentIndex)}
                dataTestId={`DropDownItem:  ${sideBarPath}/${DropDownItem.title}`} //VV - subItems of sideBar items
              />
              {isSubmenuActive && (
                <div className={styles.submenuBody}>
                  {renderDropdownItems(
                    DropDownItem.dropdown,
                    level + 1,
                    currentIndex,
                    `${sideBarPath}/${DropDownItem.title}`
                  )}
                </div>
              )}
            </>
          ) : (
            <NavLink
              className={cn(styles.link, {
                [styles.active]: pathname === DropDownItem.url,
              })}
              to={DropDownItem.url}
              onClick={onClose}
              dataTestId={`DropDownItem:  ${sideBarPath}/${DropDownItem.title}`} //VV - subItems of sideBar items
            >
              {DropDownItem.title}
              {DropDownItem.counter ? (
                <div
                  className={styles.counter}
                  style={{ backgroundColor: DropDownItem.colorCounter }}
                >
                  {DropDownItem.counter}
                </div>
              ) : (
                <Icon name="arrow-next" size="24" />
              )}
            </NavLink>
          )}
        </div>
      );
    });
  };

  return (
    <div
      className={cn(
        styles.dropdown,
        className,
        { [styles.active]: visible },
        { [styles.wide]: visibleSidebar }
      )}
    >
      <Head
        title={item.title}
        icon={item.icon}
        isActive={visible}
        onClick={handleClick}
        dataTestId={`DropDownItem: ${item.title}`} //VVVV - sideBar Items
      />
      <div className={styles.body}>
        {Array.isArray(item.dropdown) ? (
          renderDropdownItems(item.dropdown, null, 0, item.sideBarPath)
        ) : (
          <NavLink
            className={cn(styles.link, {
              [styles.active]: pathname === item.url,
            })}
            to={item.url}
            onClick={onClose}
          >
            {item.title}
            {item.counter ? (
              <div
                className={styles.counter}
                style={{ backgroundColor: item.colorCounter }}
              >
                {item.counter}
              </div>
            ) : (
              <Icon name="arrow-next" size="24" />
            )}
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
