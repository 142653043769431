import { toast } from 'react-hot-toast';

const RATE_LIMIT_INTERVAL = 60000; // Example: 1 minute in milliseconds
const MAX_GENERATION_ATTEMPTS = 5; // Example: 5 attempts

export const CheckIsUserLimited = () => {
  const attempts = JSON.parse(localStorage.getItem("generationAttempts")) || [];
  const now = Date.now();
  const filteredAttempts = attempts.filter(timestamp => now - timestamp < RATE_LIMIT_INTERVAL);

  if (filteredAttempts.length !== attempts.length) {
    localStorage.setItem("generationAttempts", JSON.stringify(filteredAttempts));
  }

  if (filteredAttempts.length >= MAX_GENERATION_ATTEMPTS) {
    const timeSinceLastAttempt = now - filteredAttempts[filteredAttempts.length - 1];
    const timeLeft = Math.ceil((RATE_LIMIT_INTERVAL - timeSinceLastAttempt) / 1000);

    if (timeSinceLastAttempt < RATE_LIMIT_INTERVAL) {
      toast.error(`You've been rate limited. Please wait for ${timeLeft} more seconds.`);
      return true;
    }
  }

  return false;
};

export const updateGenerationAttempts = () => {
  const now = Date.now();
  const attempts = JSON.parse(localStorage.getItem("generationAttempts")) || [];
  localStorage.setItem("generationAttempts", JSON.stringify([...attempts, now]));
};
