import React, { useState, useRef, useEffect } from "react";
import ApiService from "../../services/ApiService"; // Ensure this path matches your project structure
// Components
import Card from "../../components/Card";
import Image from "../../components/Image";
import CollectiblesMatrix from "../../components/CollectiblesMatrix/CollectiblesMatrix";
import cn from "classnames";
import { convertStringNumbersToNumbers } from "../../utils";
// Styles
import styles from "./Collectibles.module.sass";
import InputMatrix from "../../components/InputMatrix/InputMatrix";
import Icon from "../../components/Icon";
import ZipCreator from "../../services/ZipCreatorService";
import Dropdown from "../../components/Dropdown";
import { fetchArtifactsFromLocalStorageByType } from "../../services/LocalStorageService";
import { toast } from "react-hot-toast";
import { useAuth } from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import { useLoadingBar } from "../../contexts/LoadingBarContext";

const Collectibles = () => {
  const [contentVisible, setContentVisible] = useState(false);
  const [artifact, setArtifact] = useState(null);
  const [canGenerate, setCanGenerate] = useState(true);
  const { artifacts } = useAuth();
  const [textsList, setTextsList] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [creationsList, setCreationsList] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [generalPrompt, setPrompt] = useState("");
  const [banInfo, setBanInfo] = useState({ isBanned: false, timeLeft: 0 });
  const [isDisabled, setIsDisabled] = useState(false);
  const inputRef = useRef(null);
  const [showCardsetPrompt, setShowCardsetPrompt] = useState(false);
  const [showImagesMatrix, setShowImagesMatrix] = useState(false);
  const [isDisabledList, setIsDisabledList] = useState(Array(9).fill(true));
  const [isAllowedRegenerateList, setIsAllowedRegenerateList] = useState(
    Array(9).fill(true)
  );
  const { progress, setProgress } = useLoadingBar();

  const [ArtifactIdForStyle, setArtifactIdForStyle] = useState(null);
  const [pollingStatus, setPollingStatus] = useState([]);
  const pollingIntervals = useRef({});
  const [lastGenerateTime, setLastGenerateTime] = useState(null);
  const [showResetAllButton, setShowResetButton] = useState(true);
  const [colorPaletteOptions, setColorPaletteOptions] = useState([]);
  const [selectedColorPaletteOption, setSelectedColorPaletteOption] =
    useState(null);
  const [settings, setSettings] = useState(null);
  const { organizationData } = useAuth();
  const [isDownloadButtonVisible, setIsDownloadButtonVisible] = useState(false);
  const { artifactId } = useParams();
  useEffect(() => {
    async function fetchArtifact() {
      if (artifactId) {
        try {
          const ARTIFACT_ENDPOINT = `/get_artifact/${artifactId}`;
          setProgress(30);
          const artifactResponse = await ApiService.get(ARTIFACT_ENDPOINT);
          setProgress(60);
          console.log(artifactResponse);
          setSettings(
            convertStringNumbersToNumbers(
              artifactResponse.data.artifact.settings
            )
          );
          setProgress(100);
        } catch (error) {
          console.error("Error fetching artifact:", error);
          setProgress(0);
        }
      }
    }

    fetchArtifact();
  }, [artifactId]);

  useEffect(() => {
    const allNotNull =
      creationsList.length > 0 &&
      creationsList.every((item) => item) &&
      canGenerate;

    setIsDownloadButtonVisible(allNotNull);
  }, [creationsList, canGenerate]);

  const organizationName = organizationData["organization"];

  // Load state from localStorage when component mounts
  useEffect(() => {
    const savedState = localStorage.getItem("CollectiblesScreen");
    if (savedState) {
      const {
        contentVisible,
        artifact,
        creationsList, // Change this from recentCreations
        canGenerate,
        textsList,
        generalPrompt,
        banInfo,
        isDisabled,
        showCardsetPrompt,
        showImagesMatrix,
        isDisabledList,
        isAllowedRegenerateList,
        characterForCard9,
        pollingStatus,
        lastGenerateTime,
      } = JSON.parse(savedState);

      setContentVisible(contentVisible);
      setArtifact(artifact);
      setCanGenerate(canGenerate);
      setTextsList(textsList);
      setCreationsList(creationsList);
      setPrompt(generalPrompt);
      setBanInfo(banInfo);
      setIsDisabled(isDisabled);
      setShowCardsetPrompt(showCardsetPrompt);
      setShowImagesMatrix(showImagesMatrix);
      setIsDisabledList(isDisabledList);
      setIsAllowedRegenerateList(isAllowedRegenerateList);
      setArtifactIdForStyle(characterForCard9);
      setPollingStatus(pollingStatus || []);
      setLastGenerateTime(lastGenerateTime);
    }
  }, []);

  useEffect(() => {
    return () => {
      Object.values(pollingIntervals.current).forEach((interval) => {
        if (interval) clearInterval(interval);
      });
    };
  }, []);

  // Save state to localStorage when state changes
  useEffect(() => {
    const state = {
      contentVisible,
      artifact,
      creationsList,
      canGenerate,
      textsList,
      generalPrompt,
      banInfo,
      isDisabled,
      showCardsetPrompt,
      showImagesMatrix,
      isDisabledList,
      isAllowedRegenerateList,
      characterForCard9: ArtifactIdForStyle,
      pollingStatus,
      lastGenerateTime,
    };
    localStorage.setItem("CollectiblesScreen", JSON.stringify(state));
  }, [
    contentVisible,
    artifact,
    creationsList,
    canGenerate,
    textsList,
    generalPrompt,
    banInfo,
    isDisabled,
    showCardsetPrompt,
    showImagesMatrix,
    isDisabledList,
    isAllowedRegenerateList,
    ArtifactIdForStyle,
    pollingStatus,
    lastGenerateTime,
  ]);

  useEffect(() => {
    if (lastGenerateTime) {
      const timer = setTimeout(() => {
        setShowResetButton(true);
      }, 3 * 60 * 1000);

      return () => clearTimeout(timer);
    }
  }, [lastGenerateTime]);

  useEffect(() => {
    return () => {
      Object.values(pollingIntervals.current).forEach(clearInterval);
    };
  }, []);

  useEffect(() => {
    const selectedStyle = fetchArtifactsFromLocalStorageByType(
      artifacts,
      "scene"
    ).find((style) => style.title === ArtifactIdForStyle);
    if (
      selectedStyle &&
      Object.keys(selectedStyle?.color_palettes).length > 0
    ) {
      console.log(Object.keys(selectedStyle?.color_palettes));
      const colorPalletesNewOptions = Object.keys(
        selectedStyle?.color_palettes
      ).map((key) => ({
        label: key.includes("|") ? key.split("|")[0] : key,
        value: selectedStyle.color_palettes[key],
        content: (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {selectedStyle.color_palettes[key].map((color, index) => (
              <div
                key={index}
                style={{ backgroundColor: color, width: "20%", height: "20px" }}
              ></div>
            ))}
          </div>
        ),
      }));

      setColorPaletteOptions(colorPalletesNewOptions);
      //set the first one as default from the new
      if (!selectedColorPaletteOption) {
        setSelectedColorPaletteOption(colorPalletesNewOptions[0]);
      }
    } else {
      console.log("No color palettes found for the selected style.");
      setSelectedColorPaletteOption(null);
      setColorPaletteOptions([]);
    }
  }, [ArtifactIdForStyle, artifacts]);

  const updateDownloaded = async (creationId, creationSubId) => {
    console.log(
      "Updating download status for creation: ",
      creationId,
      creationSubId
    );
    try {
      // Simulate API call to update like status.
      ApiService.post(`/download_creation/${creationId}/${creationSubId}`);
    } catch (error) {
      console.error("Failed to toggle download status:", error);
    }
  };

  const onRegenerateClicked = async (i, j) => {
    const prompt = textsList[i * 3 + j];

    if (prompt.length < 3) {
      toast.error("The prompt should have at least 3 characters.");
      return;
    }

    const creation = creationsList[i * 3 + j];
    const idx = i * 3 + j;
    setCreationsList((prevList) =>
      prevList.map((creation, index) => (index === idx ? "" : creation))
    );
    setIsDisabledList((prevList) =>
      prevList.map((state, index) => (index === idx ? true : state))
    );
    setIsAllowedRegenerateList((prevList) =>
      prevList.map((state, index) => (index === idx ? false : state))
    );

    const requestData = {
      prompt: prompt,
      creation_id: creation.creation_id,
      creation_sub_id: creation.creation_sub_id,
      artifact_id: creation.artifact_id,
      enrich_prompt_by_chatgpt: false,
    };
    if (selectedColorPaletteOption) {
      requestData.master_color = selectedColorPaletteOption.value[0];
    }
    console.log(requestData);
    try {
      const statusResponse = await ApiService.post(
        `/start_generation`,
        requestData
      );
      const creationId = statusResponse.creation_id;
      console.log(statusResponse.creation_id);

      toast.promise(
        new Promise((resolve) => {
          setTimeout(() => {
            console.log("Starting polling for index: ", i * 3 + j);
            setPollingStatus((prevStatus) => [
              ...prevStatus,
              { taskId: creationId, idx },
            ]);
            pollTaskStatus(creationId, creationId, i * 3 + j);
            resolve();
          }, 10000); // 10 seconds delay
        }),
        {
          loading: "Preparing regeneration...",
          success: "Regeneration started successfully!",
          error: "Failed to start regeneration. Please try again later.",
        }
      );
    } catch (error) {
      console.error("Regeneration error:", error);
      toast.error(
        "Failed to regenerate the collectible. Please try again later."
      );
    }
  };

  const DownloadCardset = async () => {
    if (creationsList.some((image) => !image)) {
      console.warn("No images available to download.");
      return;
    }

    const generateUniqueNames = (creationsList) => {
      const promptCounts = {};
      return creationsList
        .map((creation, index) => {
          let baseName = textsList[index].replace(/ /g, "_"); // Replace spaces with underscores
          if (promptCounts[baseName]) {
            promptCounts[baseName]++;
            baseName += `_${promptCounts[baseName]}`;
          } else {
            // If the prompt is seen for the first time, initialize the count
            promptCounts[baseName] = 1;
          }
          return {
            name: `${baseName}.png`,
            url: creation.result_image_url[0],
          };
        })
        .filter((file) => file.url); // Ensure no empty URLs are included
    };

    const files = generateUniqueNames(creationsList, textsList);

    console.log(files);

    creationsList.map((creation) =>
      updateDownloaded(creation.creation_id, creation.creation_sub_id)
    );

    console.log(creationsList.length);

    toast
      .promise(
        ZipCreator.createZipFile(
          files,
          generalPrompt.replace(/ /g, "_") + ".zip"
        ),
        {
          loading: "Preparing for downloading...",
          success: "Download was successful.",
          error: "Failed to create zip file. Please try again later.",
        }
      )
      .catch((error) => {
        console.error("Failed to create zip file:", error);
      });
  };

  const toggleLikeStatus = (creationId, creationSubId) => {
    const index = creationsList.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id === creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }

    const updatedStatus = !creationsList[index].liked;
    const updatedCreations = creationsList.map((creation, i) => {
      if (i === index) {
        return { ...creation, liked: updatedStatus };
      }
      return creation;
    });

    toast
      .promise(
        ApiService.post(
          `/like_or_dislike_creation/${creationId}/${creationSubId}`,
          { liked: updatedStatus }
        ),
        {
          loading: "Updating like status...",
          success: () => {
            setCreationsList(updatedCreations);
            return updatedStatus
              ? "Liked successfully!"
              : "Unliked successfully!";
          },
          error: "Failed to update like status. Please try again later.",
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const pollTaskStatus = async (taskId, uniqueId, idx) => {
    if (pollingIntervals.current[taskId]) {
      clearInterval(pollingIntervals.current[taskId]);
    }
    pollingIntervals.current[taskId] = setInterval(async () => {
      try {
        const statusResponse = await ApiService.post("/check_status", [
          { creation_id: taskId },
        ]);
        console.log(statusResponse);

        const result = statusResponse?.results?.[0];

        if (!result) {
          console.error("No result found in status response");
          clearInterval(pollingIntervals.current[taskId]);
          delete pollingIntervals.current[taskId];
          return;
        }

        if (result?.status === "completed") {
          clearInterval(pollingIntervals.current[taskId]);
          delete pollingIntervals.current[taskId];
          setPollingStatus((prevStatus) =>
            prevStatus.filter((status) => status.taskId !== taskId)
          );

          const updatedCreation = result.creations?.[0] || null;

          if (idx != null && updatedCreation) {
            console.log("Updating image at index: ", idx);
            setCreationsList((prevList) =>
              prevList.map((creation, index) =>
                index === idx ? updatedCreation : creation
              )
            );
            setIsDisabledList((prevList) =>
              prevList.map((state, index) => (index === idx ? false : state))
            );
            setIsAllowedRegenerateList((prevList) =>
              prevList.map((state, index) => (index === idx ? true : state))
            );
          } else if (updatedCreation) {
            setCreationsList(result.creations);
            setIsDisabledList(Array(9).fill(false));
            setIsAllowedRegenerateList(Array(9).fill(true));
          }

          setCanGenerate(true);
        } else if (result?.status === "failed") {
          clearInterval(pollingIntervals.current[taskId]);
          delete pollingIntervals.current[taskId];
          setPollingStatus((prevStatus) =>
            prevStatus.filter((status) => status.taskId !== taskId)
          );
          toast.error("Failed to generate creations. Please try again later.");
          setCanGenerate(true);

          setCreationsList((prevCreations) =>
            prevCreations.filter(
              (creation) => !creation?.creation_id?.startsWith(uniqueId)
            )
          );
        }
      } catch (error) {
        console.error("Error polling task status:", error);
        clearInterval(pollingIntervals.current[taskId]);
        delete pollingIntervals.current[taskId];
        toast.error("Error polling task status. Please try again later.");
        setCanGenerate(true);
      }
    }, 2000);
  };

  const generateCreations = async (
    prompt,
    enrichPromptByChatGPTFlag,
    creationId
  ) => {
    setIsAllowedRegenerateList(Array(9).fill(false));
    setCanGenerate(false);

    let artifact = fetchArtifactsFromLocalStorageByType(
      artifacts,
      "character"
    ).filter((artifact) => artifact.title === ArtifactIdForStyle)[0];
    if (!artifact) {
      artifact = fetchArtifactsFromLocalStorageByType(
        artifacts,
        "scene"
      ).filter((artifact) => artifact.title === ArtifactIdForStyle)[0];
    }
    if (!artifact) {
      toast.error("Please select an artifact.");
      setCanGenerate(true);
    }

    const requestData = {
      prompt: prompt,
      enrich_prompt_by_chatgpt: enrichPromptByChatGPTFlag,
      artifact_type: artifact?.type,
      creation_id: creationId,
      artifact_id: artifact?.artifact_id,
    };
    if (selectedColorPaletteOption) {
      requestData.master_color = selectedColorPaletteOption.value[0];
    }

    const timestamp = Date.now(); // Create a unique timestamp for the session
    try {
      setCanGenerate(false);
      setCreationsList(["", "", "", "", "", "", "", "", ""]);
      console.log("Request data: ", requestData);
      const response = await ApiService.post(
        "/start_generate_collectibles",
        requestData
      );
      if (response.error) {
        throw new Error(response.error);
      }
      // Assuming the response contains a task ID to track the status
      const creationId = response.creation_id;
      console.log(response.collectibles_names);
      setTextsList(response.collectibles_names);
      setShowCardsetPrompt(true);
      setShowImagesMatrix(true);
      setLastGenerateTime(Date.now());
      setShowResetButton(false);

      setTimeout(() => {
        setPollingStatus([{ taskId: creationId, idx: null }]);
        pollTaskStatus(creationId, creationId); // Starts polling after a 40 seconds delay.
      }, 12000);
    } catch (error) {
      toast.error(
        "Failed to initiate creation generation. Please try again later."
      );
      console.error(error);

      // Optionally, remove placeholders here if the request to start generation fails
      setCreationsList((prevCreations) =>
        prevCreations.filter(
          (creation) => !creation.creation_id.startsWith("temp_" + timestamp)
        )
      );
      setCanGenerate(true);
    }
  };

  const validateInputThenGenerateCreations = () => {
    console.log(generalPrompt);

    const now = Date.now();
    const attempts =
      JSON.parse(localStorage.getItem("generationAttempts")) || [];

    if (attempts.length >= 3 && now - attempts[attempts.length - 3] < 30000) {
      const timeLeft =
        45 - Math.floor((now - attempts[attempts.length - 3]) / 1000);
      setBanInfo({ isBanned: true, timeLeft });
      toast.error(
        `You've been rate limited. Please wait for ${timeLeft} more seconds.`
      );
      localStorage.setItem(
        "generationAttempts",
        JSON.stringify([...attempts, now])
      );
      return;
    }

    if (generalPrompt.length >= 3 && ArtifactIdForStyle) {
      try {
        generateCreations(generalPrompt, false, null);
      } catch (error) {
        toast.error(error.message);
      }
      localStorage.setItem(
        "generationAttempts",
        JSON.stringify([...attempts, now])
      );
    } else {
      if (!ArtifactIdForStyle) {
        toast.error("Please select an artifact.");
      } else {
        toast.error("The prompt should have at least 3 characters.");
      }
    }
  };

  const resetAll = () => {
    setContentVisible(false);
    setArtifact(null);
    setCreationsList([]); // Change this from setRecentCreations([])
    setCanGenerate(true);
    setTextsList(["", "", "", "", "", "", "", "", ""]);
    setPrompt("");
    setBanInfo({ isBanned: false, timeLeft: 0 });
    setIsDisabled(false);
    setShowCardsetPrompt(false);
    setShowImagesMatrix(false);
    setIsDisabledList(Array(9).fill(true));
    setIsAllowedRegenerateList(Array(9).fill(true));
    setArtifactIdForStyle(null);
    setPollingStatus([]);
    setShowResetButton(false);
    setLastGenerateTime(null);
    localStorage.removeItem("CollectiblesScreen");
  };

  return (
    <div className={`${styles.shop} ${contentVisible ? styles.fadeIn : ""}`}>
      <div className={styles.background}>
        <img src={organizationData?.app_bg} alt="Background" />
      </div>

      <Card className={styles.card}>
        {<h1>Collectibles</h1>}
        <div className={styles.profile} style={{ textAlign: "center" }}>
          <div
            className={styles.details}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className={styles.wrap}>
              <div className={styles.inputButtonContainer}>
                <input
                  onKeyDown={(event) => {
                    if (
                      event.key === "Enter" &&
                      isAllowedRegenerateList.every((state) => state)
                    ) {
                      validateInputThenGenerateCreations(generalPrompt);
                    }
                  }}
                  ref={inputRef}
                  className={styles.input}
                  type="text"
                  placeholder={"Enter a prompt to generate collectibles..."}
                  onChange={(e) => {
                    setPrompt(e.target.value);
                  }}
                  value={generalPrompt}
                  disabled={banInfo.isBanned}
                />

                {!banInfo.isBanned &&
                  !isDisabled &&
                  canGenerate &&
                  isAllowedRegenerateList.every((state) => state) && (
                    <button
                      onClick={() =>
                        validateInputThenGenerateCreations(generalPrompt)
                      }
                      className={styles.button}
                    >
                      <Image
                        className={styles.pic}
                        src="/images/generate-icon-light.png"
                        srcDark="/images/generate-icon-dark.png"
                        alt="Generate"
                      />
                    </button>
                  )}
                <div>
                  <center>
                    {fetchArtifactsFromLocalStorageByType(
                      artifacts,
                      "character"
                    ).length !== 0 && (
                      <Dropdown
                        classDropdownHead={styles.dropdownHead}
                        classDropdownLabel={styles.label}
                        value={
                          ArtifactIdForStyle || "Select a character for card #9"
                        }
                        setValue={setArtifactIdForStyle}
                        options={fetchArtifactsFromLocalStorageByType(
                          artifacts,
                          "character"
                        ).map((artifact) => artifact.title)}
                      />
                    )}
                    {fetchArtifactsFromLocalStorageByType(artifacts, "scene")
                      .length !== 0 && (
                      <Dropdown
                        classDropdownHead={styles.dropdownHead}
                        classDropdownLabel={styles.label}
                        value={ArtifactIdForStyle || "Select Scene"}
                        setValue={setArtifactIdForStyle}
                        options={fetchArtifactsFromLocalStorageByType(
                          artifacts,
                          "scene"
                        ).map((artifact) => artifact.title)}
                      />
                    )}
                    {colorPaletteOptions?.length > 0 && (
                      <Dropdown
                        classDropdownHead={styles.dropdownHead}
                        classDropdownLabel={styles.label}
                        value={
                          selectedColorPaletteOption || "Select Color Palette"
                        }
                        setValue={setSelectedColorPaletteOption}
                        options={colorPaletteOptions}
                      />
                    )}
                  </center>
                </div>
              </div>
            </div>
          </div>

          <br></br>
          {showCardsetPrompt && (
            <div>
              Card Set:
              <InputMatrix
                isDisabledList={isDisabledList}
                textsList={textsList}
                setTextsList={setTextsList}
                isAllowedRegenerateList={isAllowedRegenerateList}
                onRegenerateClicked={onRegenerateClicked}
              />
            </div>
          )}
          <center>
            {showImagesMatrix && settings && (
              <CollectiblesMatrix
                creations={creationsList}
                organizationName={organizationName}
                toggleLikeStatus={toggleLikeStatus}
                regenerateCreation={onRegenerateClicked}
                inputs={textsList}
                settings={settings}
              />
            )}
            <br></br>
            {isDownloadButtonVisible && (
              <button className={cn("button-small")} onClick={DownloadCardset}>
                <div>
                  <Icon name="download" size="24" />
                  <span
                    className={styles.download}
                    style={{ pointerEvents: "auto", width: "100%" }}
                  >
                    Download Now
                  </span>
                </div>
              </button>
            )}
            {showResetAllButton && (
              <button
                className={cn("button-small", styles.resetButton)}
                style={{ marginLeft: "10px", backgroundColor: "red" }}
                onClick={resetAll}
              >
                Reset All
              </button>
            )}
          </center>
        </div>
      </Card>
    </div>
  );
};

export default Collectibles;
