const TooltipData = {
    Download: "Download",
    ReferencesOptions: "References",
    DimensionsOptions: "Dimensions",
    ImprovePrompt: "Improve the prompt to be more extensive",
    Settings: "Settings",
    Strength: <>Adjust the prompt strength when providing the image. <br /> 100% fully ignores image information;<br />Lower it to boost creativity.</>,
    Forge: "Forge",
    Variations: "Variations",
    Regenerate: "Regenerate",
    Inpainting: "Inpainting",
    Erase: "Erase",
    ColorPicker: "Color Picker",
    ColorPickerHistory: "Last 3 used colors"
}

export default TooltipData
