import React from "react";
import cn from "classnames";
import styles from "./Control.module.sass";
import Icon from "../../Icon";
import CustomTooltip from "../../CustomTooltip";

const Control = ({
  className,
  onLikeDislike,
  item,
  onDownload,
  onRegenerateClick,
  onGenerateVariationsClick,
  onSelectQuest,
}) => {
  const actions = [];

  if (onSelectQuest) {
    actions.push({
      title: "selectQuest",
      url: "/images/svg/checkmark_3.png",
      action: (event) => {
        event.stopPropagation();
        onSelectQuest(item.id);
      },
    });
  }

  if (onGenerateVariationsClick) {
    actions.push({
      title: "Variations",
      url: "/images/svg/variations.png",
      action: (event) => {
        event.stopPropagation();
        onGenerateVariationsClick(item.id);
      },
    });
  }

  if (onRegenerateClick) {
    actions.push({
      title: "Regenerate",
      icon: "repeat",
      action: (event) => {
        event.stopPropagation();
        onRegenerateClick(item.id);
      },
    });
  }

  if (onDownload) {
    actions.push({
      title: "Download",
      icon: "download",
      action: () => {
        onDownload(item);
      },
      style: {},
    });
  }

  if (onLikeDislike) {
    actions.push({
      title: item.liked ? "Dislike" : "Like",
      icon: item.liked ? "heart-fill" : "heart",
      action: (event) => {
        event.stopPropagation();
        onLikeDislike(item.id);
      },
      style: item.liked ? { fill: "red" } : {},
      liked: item.liked, // Add this to conditionally apply styles
    });
  }


  return (
    <div className={cn(styles.control, className)}>
      {actions.map((x, index) => (
        <CustomTooltip id={x.title} title={x.title} placement="bottom">
          <button
            className={cn(styles.button, { [styles.liked]: x.liked })}
            key={index}
            onClick={x.action}
            style={x.style}
          >
            {x.icon && <Icon name={x.icon} size="20" />}
            {x.url && <Icon src={x.url} size="32" />}
          </button>
        </CustomTooltip>
      ))}
    </div>
  );
};

export default Control;
