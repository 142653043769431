import React from 'react';
import { useDroppable } from '@dnd-kit/core';

export default function Droppable({ id, children, style }) {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  const calculatedStyle = {
    // background: isOver ? 'rgba(0, 255, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...style
  };

  return (
    <div ref={setNodeRef} style={calculatedStyle}>
      {children}
    </div>
  );
}
