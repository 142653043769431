import React, { useState } from "react";
import cn from "classnames";
import ApiService from "../../services/ApiService"; // Ensure this path matches your project structure
import { toast } from "react-hot-toast";
import { useLoadingBar } from "../../contexts/LoadingBarContext";
import Card from "../../components/Card";
import { useAuth } from "../../contexts/AuthContext";

// Styles
import styles from "./AddData.module.sass";

const AddDataScreen = () => {
  const { progress, setProgress } = useLoadingBar();
  const { organizationData } = useAuth();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // State to track upload progress

  const validateImageFiles = (files) => {
    return files.filter((file) => file.type.startsWith("image/"));
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = Array.from(event.dataTransfer.files);
    const imageFiles = validateImageFiles(files);

    if (imageFiles.length !== files.length) {
      toast.error(
        "Only image files are allowed. Non-image files were removed."
      );
    }

    setSelectedFiles(imageFiles);
    if (imageFiles.length > 0) {
      toast.success("Image files uploaded successfully!");
    }
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    const imageFiles = validateImageFiles(files);

    if (imageFiles.length !== files.length) {
      toast.error(
        "Only image files are allowed. Non-image files were removed."
      );
    }

    setSelectedFiles(imageFiles);
    if (imageFiles.length > 0) {
      toast.success("Image files selected successfully!");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const uploadFiles = async () => {
    if (selectedFiles.length === 0) {
      toast.error("Please select files to upload.");
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    setIsUploading(true); // Set uploading state to true

    toast
      .promise(
        (async () => {
          const response = await ApiService.post("/add_data", formData);

          if (response.message) {
            setSelectedFiles([]); // Reset selected files after successful upload
            return response.message;
          } else {
            throw new Error("Failed to upload files");
          }
        })(),
        {
          loading: "Uploading files...",
          success: "Files uploaded successfully!",
          error: "Failed to upload files.",
        }
      )
      .finally(() => {
        setProgress(0); // Reset progress after the toast promise is resolved
        setIsUploading(false); // Reset uploading state to false
      });
  };

  const triggerFileSelect = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <div className={styles.container}>
      <div className={styles.background}>
        <img src={organizationData?.app_bg} alt="Background" />
      </div>
      <Card className={styles.card}>
        <h1>Add Data</h1>
        {!isUploading && (
          <div
            className={cn(styles.dropzone, { [styles.dragging]: isDragging })}
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            <p>
              <center>
                Drag & drop files here<br></br>or click to select files
              </center>
            </p>
            <input
              type="file"
              id="fileInput"
              multiple
              className={styles.fileInput}
              onChange={handleFileSelect}
              disabled={isUploading} // Disable input while uploading
            />
          </div>
        )}
        {!isUploading && (
          <>
            <button onClick={triggerFileSelect} className={styles.uploadButton}>
              Select Files
            </button>
            <button onClick={uploadFiles} className={styles.uploadButton}>
              Upload Files
            </button>
          </>
        )}
        {selectedFiles.length > 0 && (
          <div className={styles.fileList}>
            <h2>Selected Files:</h2>
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>
        )}
      </Card>
    </div>
  );
};

export default AddDataScreen;
