import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";
import styles from "./Users.module.sass";
import { Link } from "react-router-dom";


const Users = ({ className , artifacts, itemWidth, itemHeight}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true); // Initially assume there's more to scroll
  const containerRef = useRef();

  const updateArrowVisibility = () => {
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
  };

  const handleScroll = (scrollAmount) => {
    const newScrollPosition = scrollPosition + scrollAmount;
    setScrollPosition(newScrollPosition);
    containerRef.current.scrollLeft = newScrollPosition;
    updateArrowVisibility();
  };

  const handleWheel = (event) => {
    event.preventDefault();
    const newScrollPosition = containerRef.current.scrollLeft + event.deltaY;
    setScrollPosition(newScrollPosition);
    containerRef.current.scrollLeft = newScrollPosition;
    updateArrowVisibility();
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('wheel', handleWheel);
    updateArrowVisibility();

    return () => {
      container.removeEventListener('wheel', handleWheel);
    };
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  return (
    <div className={cn(styles.users, className)}>
      <div className={styles.list} ref={containerRef} style={{
        display: 'flex',
        overflowX: 'scroll',
        scrollBehavior: 'smooth'
      }}>
        {artifacts?.map((artifact, index) => (
          <Link
            className={styles.item}
            key={index}
            to={"/artifacts/" + artifact.artifact_id}
            style={{
              backgroundImage: `url(${artifact.display_image_url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              width: itemWidth, // ensure all items have the same width,
              height: itemHeight, // ensure all items have the same height,
              flex: "0 0 auto" // prevent items from growing or shrinking
            }}
          >
            {/* Optional: Display additional artifact info here */}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Users;
