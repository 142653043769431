import React from "react";
import { useDraggable } from "@dnd-kit/core";

export default function Draggable({ id, children, className }) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        position: "absolute",
      }
    : undefined;

  return (
    <div>
      <div
        ref={setNodeRef}
        style={style}
        {...listeners}
        {...attributes}
        className={className}
      >
        {children}
      </div>
      {transform && (
        <div {...listeners} {...attributes} className={className}>
          {children}
        </div>
      )}
    </div>
  );
}
