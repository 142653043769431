import React, { useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Card from "../../../components/Card";
import Users from "../../../components/Users";
import PromptBox from "../../../components/PromptBox";
import GenerationService from "../../../services/GenerationService";
import { useAuth } from "../../../contexts/AuthContext";

const Overview = ({ className }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { artifacts } = useAuth();
  const characters = artifacts.filter(
    (artifact) => artifact.type === "character"
  );
  const charactersForcedSuggestions = artifacts
    .filter(
      (artifact) => artifact.type === "character" || artifact.type == "scene"
    )
    .reduce((acc, artifact) => {
      acc[artifact.display_name] = "";
      return acc;
    }, {});
  const itemsForcedSuggestions = artifacts
    .filter((artifact) => artifact.type === "item")
    .reduce((acc, artifact) => {
      acc[artifact.display_name] = "";
      return acc;
    }, {});

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Quick Start"
        classTitle="title-purple"
      >
        <center>
          <PromptBox
            canGenerate={true}
            OnGenerateClicked={GenerationService.startGeneration}
            forcedItemsSuggestions={itemsForcedSuggestions}
            forcedCharacterSuggestions={charactersForcedSuggestions}
          />
        </center>
      </Card>
      {characters && characters.length > 0 && (
        <Card
          className={cn(styles.card, className)}
          title="Or Choose a Character to Begin Creation!"
          classTitle="title-purple"
        >
          <div className={styles.overview}>
            <div className={styles.body}>
              {activeIndex === 0 && (
                <Users
                  artifacts={characters}
                  itemHeight={200}
                  itemWidth={200}
                />
              )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default Overview;
