import React, { useState, useRef, useEffect } from 'react';
import Image from "../Image";
import styles from "./ColorPicker.module.sass";
import { ChromePicker } from 'react-color';
import { Box } from '@mui/material';
import CustomTooltip from '../CustomTooltip/index';
import TooltipData from '../CustomTooltip/TooltipsData';
import { set } from 'date-fns';

const ColorPicker = ({ masterColor, setMasterColor, artifact }) => {
    const[displayColorPicker, setDisplayColorPicker] = useState(false)
    const[color, setColor] = useState('')
    const[pickedColor, setPickedColor] = useState('')
    const pickerRef = useRef(null);
    const buttonRef = useRef(null);


    const historyColors = [artifact?.color_history[0],artifact?.color_history[1] ,artifact?.color_history[2]]
    // const historyColors = ['#3039c4', '#00ffff', '#fe5555']

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    }

    const handleClose = () => {
        setDisplayColorPicker(false)
    }

    const handleClear = () => {
        setColor('')
        setPickedColor()
        setMasterColor()
    }

    const handleChange = (newColor) => {
        setColor(newColor.hex);
    };

    const handlePick = () => {
        console.log('Color picked:', color)
        setPickedColor(color);
        setMasterColor(color)
        handleClose();
    }

    const handleChooseFromHistory = (event) => {
        const newColor = event.target.style.backgroundColor; // Get the background color from the clicked button
        setColor(newColor); // Update the color to the chosen history color
        setMasterColor(newColor);
        console.log('Color changed to:', newColor);
    }

    useEffect(() => {
        const adjustPickerPosition = () => {
            if (pickerRef.current && buttonRef.current) {
                const pickerRect = pickerRef.current.getBoundingClientRect();
                const buttonRect = buttonRef.current.getBoundingClientRect();
                const viewportWidth = window.innerWidth;
                const viewportHeight = window.innerHeight;

                // Default positions
                let newLeft = '53px';
                let newTop = '6px';

                // Check if picker exceeds the right edge of the screen
                if (pickerRect.right > viewportWidth) {
                    newLeft = `${-pickerRect.width + 4}px`; // Adjust to position left of the button
                }
                // if (pickerRect.bottom > viewportHeight && pickerRect.right <= viewportWidth) {
                //     newTop = `${-pickerRect.height + 1}px`; // Adjust to position left of the button
                // }

                pickerRef.current.style.left = newLeft;
                // pickerRef.current.style.top = newTop;
            }
        };

        if (displayColorPicker) {
            adjustPickerPosition();
            window.addEventListener('resize', adjustPickerPosition);
        }

        return () => {
            window.removeEventListener('resize', adjustPickerPosition);
        };
    }, [displayColorPicker]);



useEffect(() => {
    function handleClickOutside(event) {
        // Check if the click is inside the picker container
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
            // Check if the click is not on the scrollbar
            if (
                event.target.tagName !== 'HTML' &&  // Prevent closing on scrollbar clicks
                event.target.tagName !== 'BODY' &&
                event.target.tagName !== 'BUTTON' &&  // Prevent closing when clicking on the button itself
                event.target.tagName !== 'IMG'  // Prevent closing when clicking on the image
            ) {
                handleClose();
            }
        }
    }

    if (displayColorPicker) {
        document.addEventListener('mousedown', handleClickOutside);
    } else {
        document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
    }, [displayColorPicker]);


return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
        <CustomTooltip
            title={TooltipData["ColorPicker"]}
            placement="top">
        <button onClick={handleClick}
            ref={buttonRef}
            className={styles.ColorPaletteButton}
            >
            <Image
                className={styles.pic}
                src={`${process.env.PUBLIC_URL}/images/colorPalette.png`}
                // src="/images/generate-icon-dark.png"
                alt="colorPicker"
                style={{
                    objectFit: 'contain',
                    height: "40px",
                    width: "40px",  // Ensure the image fits within the specified size
                }}
            />
        </button>
        </CustomTooltip>
    {pickedColor && (
        <div
            onClick={handleClick}
            style={{
                    backgroundColor: pickedColor,
                    borderRadius: "50%",
                    width: "37px",
                    height: "37px",
                    marginTop: "5px", // Spacing below the color palette icon
                    marginLeft: "11px"// Spacing next to the color palette icon
                    }}
                ></div>
            )}
    {displayColorPicker && (
                <Box
                    ref={pickerRef}
                    className={styles.ColorPicker}
                    style={{
                        position: 'absolute',
                        // position: 'fixed',
                        // zIndex: 5,
                        zIndex: 9999,
                        left: '53px',
                        top: '6px', // Position below the button
                        background: 'white',
                        padding: '10px',
                        boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
                        borderRadius: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <ChromePicker color={color} onChange={handleChange} />
                    <button onClick={handleClear}
                    style={{
                            marginTop: '10px',
                            cursor: 'pointer',
                            background: "#9CA5A6",
                            color: 'white',
                            fontSize: '15px',
                            borderRadius: '10px',
                            width: '100px',
                            height: '25px',
                        }}>
                        Clear
                    </button>
                    <button
                        style={{
                            marginTop: '8px',
                            padding: '8px 16px',
                            cursor: 'pointer',
                            // background: '#007BFF',
                            background: color,
                            color: 'white',
                            fontSize: '20px',
                            border: 'none',
                            borderRadius: '10px',
                            width: '150px',
                            height: '40px',
                            marginBottom: '10px'
                        }}
                        onClick={handlePick}
                    >
                        Pick
                    </button>
                    <CustomTooltip title={TooltipData["ColorPickerHistory"]} placement="left">
                        <p style={{fontSize: 18, marginBottom: 7}}> History: </p>
                    </CustomTooltip>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '75%' }}>
                        <button id="previousColor1" onClick={handleChooseFromHistory} style={{backgroundColor: historyColors[0], borderRadius: "50%", width: "36px", height:"36px", padding: "10px"}}></button>
                        <button id="previousColor2" onClick={handleChooseFromHistory} style={{backgroundColor: historyColors[1], borderRadius: "50%", width: "36px",height:"36px",padding: "10px"}}></button>
                        <button id="previousColor3" onClick={handleChooseFromHistory} style={{backgroundColor: historyColors[2], borderRadius: "50%", width: "36px",height:"36px",padding: "10px"}}></button>
                    </div>
                </Box>
            )}
        </div>
    );
}
export default ColorPicker
