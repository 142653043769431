// Dialog.js
import React from 'react';
import styles from './Dialog.module.sass'; // Adjust the import path to your styles

const Dialog = ({ isVisible, onClose, onConfirm, message, confirmText, cancelText }) => {
    if (!isVisible) return null;

    return (
        <div className={styles.dialogOverlay}>
            <div className={styles.dialog}>
                <p>{message}</p>
                <div className={styles.dialogActions}>
                    <button onClick={onConfirm}>{confirmText}</button>
                    <button onClick={onClose}>{cancelText}</button>
                </div>
            </div>
        </div>
    );
};

export default Dialog;
