import React, { useState } from "react";
import cn from "classnames";
import styles from "./Product.module.sass";
import Control from "./Control";
import Loader from "../Loader";
import { TripleRollingOfferOption } from "../../screens/GenerateOfferScreen";
import { PersonalOffer6Option } from "../../screens/GenerateOfferScreen";

const componentMapping = {
  "triple-rolling-offer": TripleRollingOfferOption,
  "personal-offer-6": PersonalOffer6Option,
  // Add other mappings here
};

const CreationCard = ({
  className,
  item,
  onShowPreview,
  onLikeDislike,
  onRegenerateClick,
  onGenerateVariationsClick,
  showPrompt,
  fixedSize,
  onSelectQuest,
}) => {
  const [visible, setVisible] = useState(false);
  const isNotSimple = componentMapping.hasOwnProperty(item?.creation_type);

  // Determine the component to render based on creation_type
  const CreationComponent = componentMapping[item?.creation_type];
  return (
    <div
      className={cn(styles.product, className, {
        [styles.active]: visible,
        [styles.tripleRollingOffer]: isNotSimple,
      })}
      style={fixedSize ? { width: fixedSize, height: fixedSize } : {}}
      status={item?.status}
      creation_id={item?.creation_id}
      creation_sub_id={item?.creation_sub_id}
      onClick={() => {
        if (onShowPreview && item?.status == "completed") {
          onShowPreview(item);
        }
      }}
    >
      <div
        className={cn(styles.preview, {
          [styles.centerContent]:
            !item?.result_image_url && !item?.is_place_holder,
          [styles.loading]: !item?.result_image_url && !item?.is_place_holder, // Add this line
          [styles.placeHolder]: item?.is_place_holder, // Add this line
        })}
        style={{ height: fixedSize, width: fixedSize, marginTop: -16 }}
      >
        <Control
          className={styles.control}
          item={item}
          onSelectQuest={
            onSelectQuest ? () => onSelectQuest(item?.creation_id) : null
          }
          onLikeDislike={
            onLikeDislike ? () => onLikeDislike(item?.creation_id) : null
          }
          onRegenerateClick={
            onRegenerateClick
              ? () => onRegenerateClick(item?.creation_id)
              : null
          }
          onGenerateVariationsClick={
            onGenerateVariationsClick
              ? () => onGenerateVariationsClick(item?.creation_id)
              : null
          }
          isLiked={item?.isLiked}
        />
        {item?.is_place_holder ? (
          <div className={styles.grayRectangle}></div>
        ) : item?.result_image_url ? (
          CreationComponent ? (
            <CreationComponent creation={item} />
          ) : (
            <img
              srcSet={`${item?.result_image_url} 2x`}
              src={item?.result_image_url}
              alt={item?.display_name}
            />
          )
        ) : (
          <Loader />
        )}
      </div>
      <div className={styles.line}>
        {showPrompt !== false && (
          <div className={styles.title}>{item.prompt}</div>
        )}
      </div>
    </div>
  );
};

export default CreationCard;
