import React from "react";
import stylesTooltip from "./CustomTooltip.module.sass";
// import { Tooltip } from "@material-ui/core";
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';

const CustomTooltip = ({ id, title, children, placement, classes}) => {

  const handleClick = (event) => {
    const tooltip = event.currentTarget.querySelector('[role="tooltip"]');
    if (tooltip) {
      tooltip.style.display = 'none'; // Hide the tooltip on click
    }
  }

  return (
    <>
      <Tooltip
        //leaveDelay={150000}
        TransitionComponent={Fade}
        enterDelay={900}
        // id={id}
        title={title}
        placement={placement}
        classes={{
          // tooltip: stylesTooltip[`customTooltip${id}`],
          tooltip: stylesTooltip[`customTooltipBase`],
          arrow: stylesTooltip.customArrow,
          ...classes,
        }}
      >
        {children}
      </Tooltip>
    </>
  );
};

export default CustomTooltip;
