// DialogContext.js
import React, { createContext, useContext, useState } from 'react';
import Dialog from '../components/Dialog/Dialog';

const DialogContext = createContext();

export const useDialog = () => {
    return useContext(DialogContext);
};

export const DialogProvider = ({ children }) => {
    const [dialog, setDialog] = useState({
        isVisible: false,
        message: '',
        onConfirm: null,
        onClose: null,
        confirmText: 'Yes',
        cancelText: 'No',
    });

    const showDialog = (message, onConfirm, onClose, confirmText = 'Yes', cancelText = 'No') => {
        setDialog({
            isVisible: true,
            message,
            onConfirm,
            onClose,
            confirmText,
            cancelText,
        });
    };

    const closeDialog = () => {
        setDialog((prev) => ({
            ...prev,
            isVisible: false,
        }));
    };

    return (
        <DialogContext.Provider value={{ showDialog, closeDialog }}>
            {children}
            <Dialog {...dialog} onClose={closeDialog} />
        </DialogContext.Provider>
    );
};
