import JSZip from "jszip";
import toast from "react-hot-toast";

class ZipCreator {
  static createZipFile = async (files, zip_file_name) => {
    const zip = new JSZip();

    // Function to fetch and add each file to the zip
    const addFileToZip = async (file) => {
      const tryFetch = async (url) => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/octet-stream",
            },
          });

          if (!response.ok) {
            const errorMessage = `Failed to fetch file: ${file.name} from ${url}. Status: ${response.status} ${response.statusText}`;
            throw new Error(errorMessage);
          }

          return await response.blob();
        } catch (error) {
          console.error(
            `Error fetching file ${file.name} from ${url}:`,
            error.message
          );
          throw error; // Re-throw to catch outside
        }
      };

      try {
        console.log(`Trying HTTP for file: ${file.name}`);
        let url = file.url.replace("https://", "http://");
        let content = await tryFetch(url);

        // If successful, add the file to the zip
        zip.file(file.name, content);
        console.log(`File added to zip: ${file.name} (HTTP)`);
      } catch (error) {
        console.log(`HTTP failed for file: ${file.name}, trying HTTPS...`);
        try {
          let url = file.url.replace("http://", "https://");
          let content = await tryFetch(url);

          // If successful, add the file to the zip
          zip.file(file.name, content);
          console.log(`File added to zip: ${file.name} (HTTPS)`);
        } catch (finalError) {
          console.error(
            `Failed to fetch file ${file.name} from both HTTP and HTTPS.`
          );
          toast.error(
            `Failed to fetch file ${file.name} from both HTTP and HTTPS.`
          );
        }
      }
    };

    // Create promises array for all file fetches
    const promises = files.map((file) => addFileToZip(file));

    // Wait for all promises to resolve and then generate the zip
    await Promise.all(promises);

    // Generate the zip file and trigger download
    zip.generateAsync({ type: "blob" }).then(function (content) {
      // Force download
      const link = document.createElement("a");
      link.download = zip_file_name ? zip_file_name + ".zip" : "result.zip";
      link.href = window.URL.createObjectURL(content);
      link.click();
    });
  };

  render() {
    return null; // Since this is just a utility component, render null
  }
}

export default ZipCreator;
