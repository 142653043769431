import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import styles from "./ActivePassivePromptBox.module.sass";
import Image from "../../../components/Image";
import { toast } from "react-hot-toast";

const ActivePassivePromptBox = ({ className, OnGenerateClicked, error, canGenerate, placeholder, activePrompt, setActivePrompt, passivePrompt, setPassivePrompt }) => {
  const [visible, setVisible] = useState(false);
  const [banInfo, setBanInfo] = useState({ isBanned: false, timeLeft: 0 });
  const [isDisabled, setIsDisabled] = useState(false);
  const [enrichPromptByChatGPTFlag, setEnrichPromptByChatGPTFlag] = useState(true);

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const attempts = JSON.parse(localStorage.getItem("generationAttempts")) || [];
      const now = Date.now();
      const filteredAttempts = attempts.filter(timestamp => now - timestamp < 45000);

      if (filteredAttempts.length !== attempts.length) {
        localStorage.setItem("generationAttempts", JSON.stringify(filteredAttempts));
      }

      if (filteredAttempts.length >= 3) {
        const timeSinceLastAttempt = now - filteredAttempts[2];
        if (timeSinceLastAttempt < 45000) {
          setBanInfo({ isBanned: true, timeLeft: Math.ceil((45000 - timeSinceLastAttempt) / 1000) });
        } else {
          setBanInfo({ isBanned: false, timeLeft: 0 });
        }
      } else {
        setBanInfo({ isBanned: false, timeLeft: 0 });
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const validateInputThenGenerateCreations = () => {
    const now = Date.now();
    const attempts = JSON.parse(localStorage.getItem("generationAttempts")) || [];

    if (attempts.length >= 3 && now - attempts[attempts.length - 3] < 30000) {
      const timeLeft = 45 - Math.floor((now - attempts[attempts.length - 3]) / 1000);
      setBanInfo({ isBanned: true, timeLeft });
      toast.error(`You've been rate limited. Please wait for ${timeLeft} more seconds.`);
      localStorage.setItem("generationAttempts", JSON.stringify([...attempts, now]));
      return;
    }

    if (activePrompt.length >= 3 && passivePrompt.length >= 3) {
      try {
        OnGenerateClicked(4, false, enrichPromptByChatGPTFlag);
      } catch (error) {
        toast.error(error.message);
      }
      localStorage.setItem("generationAttempts", JSON.stringify([...attempts, now]));
    } else {
      toast.error("Enter prompts in both text boxes (min. 3 characters each).");
    }
  };

  return (
    <>
      <div className={cn(styles.search, className, { [styles.active]: visible })}>
        <div className={styles.head}>
          <br />
          <div className={styles.inputButtonContainer}>
            <input
              ref={inputRef}
              className={styles.input}
              type="text"
              placeholder={placeholder ? placeholder : 'Prompt for "free" UI background'}
              value={activePrompt}
              onChange={(e) => {
                setVisible(true);
                setActivePrompt(e.target.value);
              }}
              disabled={banInfo.isBanned}
            />
          </div>
          <div className={styles.inputButtonContainer}>
            <input
              ref={inputRef}
              className={styles.input}
              type="text"
              placeholder={placeholder ? placeholder : 'Prompt for "locked" UI background'}
              value={passivePrompt}
              onChange={(e) => {
                setVisible(true);
                setPassivePrompt(e.target.value);
              }}
              disabled={banInfo.isBanned}
            />
            {(!banInfo.isBanned && !isDisabled) && (
              <button
                onClick={validateInputThenGenerateCreations}
                className={styles.button}
              >
                <Image
                  className={styles.pic}
                  src="/images/generate-icon-light.png"
                  srcDark="/images/generate-icon-dark.png"
                  alt="Generate"
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivePassivePromptBox;
