const baseContainer = {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#dba47a",
    borderRadius: 10,
    justifyContent: "center",
    marginTop: 80,
    paddingTop: 60,
}

const collectiblesDict = {

    innplay: {
        height: 185,
        width: 170,
        frameImage: `${process.env.PUBLIC_URL}/images/collectiblesFrame.png`,
        frameTop: -22,
        frameLeft: -3,
        containerStyle: {
            ...baseContainer,
            gap: 20,
            width: 700,
        },
        promptTextBottom: 43,
        fontSize: 15,
        framePadding: 5
    },

    communix: {
        height: 164,
        width: 104,
        frameImage: `${process.env.PUBLIC_URL}/images/communix_frame.png`,
        frameHeight: 168,
        frameWidth: 104,
        frameTop: 0,
        frameLeft: -5,
        backgroundImage: `${process.env.PUBLIC_URL}/images/communix_collectibles_background.png`,
        containerStyle:{
            ...baseContainer,
            backgroundSize: "100% 100%",
            gap: 10,
            paddingTop: 216,
            backgroundRepeat: "no-repeat",
            height: 734,
            width: 411,
        },
        borderRadius: 10,
        promptTextBottom: 3,
        fontSize: 11,
        framePadding: 4
    },
};

export default collectiblesDict;
