import React, { useEffect, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";
import CustomTooltip from "../CustomTooltip";
import TooltipData from "../CustomTooltip/TooltipsData";

const Dropdown = ({
  id,
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  style,
  showToolTip = false,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (selectedValue) => {
    setValue(selectedValue);
    setVisible(false);
  };
// useEffect(()=>{
//   onDropdownToggle(visible)
// }, [visible, onDropdownToggle])

  const isScrollable = options.length > 5;

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
        style={{ ...style }}
      >
          {showToolTip ? (
            <CustomTooltip
            // id={id}
            title={TooltipData[id]} placement="top">
            <div
              className={cn(styles.head, classDropdownHead)}
              onClick={() => setVisible(!visible)}
            >
              <div className={styles.selection}>
                {typeof value === "object" && value.content ? (
                  <div style={{ paddingTop: 14 }}>{value.content}</div>
                ) : (
                  value
                )}
              </div>
            </div>
          </CustomTooltip>
        ): (<div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection}>
            {typeof value === "object" && value.content ? (
              <div style={{ paddingTop: 14 }}>{value.content}</div>
            ) : (
              value
            )}
          </div>
        </div>)}
        {visible && (
          <div
            className={cn(styles.body, {
              [styles.bodyUp]: upBody,
              [styles.scrollable]: isScrollable,
            })}
          >
            {options.map((x, index) => (
              <div
                className={cn(styles.option, {
                  [styles.selectioned]: x === value,
                })}
                onClick={() => handleClick(x)}
                key={index}
              >
                {typeof x === "object" && x.content ? (
                  <>
                    <div>{x.label}</div>
                    {x.content}
                  </>
                ) : (
                  x
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
