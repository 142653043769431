import React, { useState } from "react";
import cn from "classnames";
import styles from "../SignIn/SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";

import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { validatePassword, validateResetCode } from "../../utils.js";


const ForgotPassword = () => {
  const heightWindow = use100vh();
  const { sendResetCode, resetPassword } = useAuth(); // Adjusted for forgot password functions

  // State for input fields and managing loading/error states
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSendCode = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await sendResetCode(email); // Assuming sendResetCode function can handle this
      setStep(2);
    } catch (error) {
      toast.error('Sending reset code failed: ' + error.message);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if(!validateResetCode(code)){
        return
      }
      if(!validatePassword(newPassword)){
        return
      }

      let response = await resetPassword(email, code, newPassword); // Assuming resetPassword function can handle these
      console.log(response)
      navigate("/sign-in");
    } catch (error) {
      toast.error('Resetting password failed: ' + error.message);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link>
        <div className={cn("h2", styles.title)}>Forgot Password</div>
        {step === 2 && <div className={cn("h5", styles.title)}>A passcode has been sent to your email. Please enter it below.</div>}
        {step === 1 && (
          <form onSubmit={handleSendCode}>
            <TextInput
              className={styles.field}
              name="email"
              type="email"
              placeholder="Your email"
              required
              icon="mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className={cn("button", styles.button)} disabled={isLoading}>Send Reset Code</button>
          </form>
        )}
        {step === 2 && (
          <form onSubmit={handleResetPassword}>
            <TextInput
              className={styles.field}
              name="code"
              type="text"
              placeholder="Reset Code"
              required
              icon="key"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <TextInput
              className={styles.field}
              name="newPassword"
              type="password"
              placeholder="New Password"
              required
              icon="lock"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button className={cn("button", styles.button)} disabled={isLoading}>Reset Password</button>
          </form>
        )}
        <div className={styles.info}>
          Remembered your password?{" "}
          <Link className={styles.link} to="/sign-in">
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
