import ApiService from "./services/ApiService";
import { toast } from "react-hot-toast";

export const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const progress = () => {
  return Math.floor(Math.random() * 90) + 10 + "%";
};

export const convertImageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result.split(",")[1]);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
export function formatTimestampToLocalTime(ns) {
  // Convert nanoseconds to milliseconds
  const ms = ns / 1e6;

  // Create a new Date object with the milliseconds
  const date = new Date(ms);

  // Extract hours, minutes, day, month, and year in local time
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
  const year = date.getFullYear().toString().slice(-2);

  // Format the date and time
  return `${hours}:${minutes} ${day}/${month}/${year}`;
}

export const downloadImage = (imageUrl, fileName) => {
  const link = document.createElement("a");
  link.href = imageUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const updateDownloaded = async (creationId, creationSubId) => {
  try {
    await ApiService.post(`/download_creation/${creationId}/${creationSubId}`);
  } catch (error) {
    console.error("Failed to update download status:", error);
  }
};

export const errorMessages = {
  leadingSpaces: "- Should not have leading spaces.",
  trailingSpaces: "- Should not have trailing spaces.",
  uppercase: "- Contain at least one uppercase letter.",
  lowercase: "- Contain at least one lowercase letter.",
  digit: "- Contain at least one digit.",
  specialCharacter: "- Contain at least one special character.",
  length: "- Length should be between 8 and 256 characters.",
};

export function validatePassword(password) {
  const results = [];

  // Step 1: Check for leading spaces
  if (/^\s+/.test(password)) {
    results.push(errorMessages.leadingSpaces);
  }

  // Step 2: Check for trailing spaces
  if (/\s+$/.test(password)) {
    results.push(errorMessages.trailingSpaces);
  }

  // Step 3: Check for at least one uppercase letter
  if (!/[A-Z]/.test(password)) {
    results.push(errorMessages.uppercase);
  }

  // Step 4: Check for at least one lowercase letter
  if (!/[a-z]/.test(password)) {
    results.push(errorMessages.lowercase);
  }

  // Step 5: Check for at least one digit
  if (!/[0-9]/.test(password)) {
    results.push(errorMessages.digit);
  }

  // Step 6: Check for at least one special character
  if (!/[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\-]/.test(password)) {
    results.push(errorMessages.specialCharacter);
  }

  // Step 7: Check the length of the string (between 8 and 256 characters)
  if (password.length < 8 || password.length > 256) {
    results.push(errorMessages.length);
  }

  if (results.length > 0) {
    toast.error(`Password is invalid!\n${results.join("\n")}`, {
      autoClose: 5000 + results.length * 1000, // Using autoClose instead of duration
    });
    return false;
  }
  return true;
}

export function validateResetCode(code) {
  if (!/^\d{6}$/.test(code)) {
    toast.error(
      `An email has been sent to you with a 6-digit verification code. Please enter the code exactly as it appears in the email`,
      {
        autoClose: 7000,
      }
    );
    return false;
  }
  return true;
}

export function convertStringNumbersToNumbers(settings) {
  const result = {};

  for (const key in settings) {
    if (typeof settings[key] === "string" && !isNaN(settings[key])) {
      result[key] = Number(settings[key]);
    } else if (typeof settings[key] === "object" && settings[key] !== null) {
      result[key] = convertStringNumbersToNumbers(settings[key]);
    } else {
      result[key] = settings[key];
    }
  }

  return result;
}
