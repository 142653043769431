import React from "react";
import styles from "./PromptBox.module.sass";

const ImageUpload = ({ imagePreview, handleFileChange, clearImage, fileInputRef }) => {
  return (
    <div className={styles.imageUpload}>
      {imagePreview ? (
        <div className={styles.previewImage}>
          <img src={imagePreview} alt="Preview" width={300} />
          <br></br>
          <button onClick={clearImage}>Clear</button>
        </div>
      ) : (
        <div>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
