import React, { createContext, useContext, useState, useEffect } from "react";

// Create the context
const PermissionsContext = createContext({
  permissions: {},
  setPermissions: () => {}, // Default empty implementation
});

// Export the custom hook for easier consumption
export const usePermissions = () => useContext(PermissionsContext);

// Context provider component
const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(() => {
    // Attempt to get the stored permissions from localStorage
    const savedPermissions = localStorage.getItem("permissions");
    return savedPermissions ? JSON.parse(savedPermissions) : {};
  });

  useEffect(() => {
    // Store permissions in localStorage whenever they change
    localStorage.setItem("permissions", JSON.stringify(permissions));
    console.log("Permissions updated");
  }, [permissions]);

  return (
    <PermissionsContext.Provider value={{ permissions, setPermissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsProvider;
