import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Adjust the import path as needed

export default function RequireAuth() {
    const { currentUser } = useAuth();
    const location = useLocation();

    // console.log('currentUser:', currentUser);
    if (!currentUser) {
        // Redirect them to the /sign-in page, but save the current location they were trying to go to
        return <Navigate to="/sign-in" state={{ from: location }} replace />;
    }

    // Use Outlet to render child routes
    return <Outlet />;
}
