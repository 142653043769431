import React, { useState, useEffect } from 'react';
import styles from './InputMatrix.module.sass';
import Icon from '../Icon';

  const InputMatrix = ({ isDisabledList, textsList, setTextsList, isAllowedRegenerateList, onRegenerateClicked }) => {
    const [prompts, setPrompts] = useState(textsList);

    useEffect(() => {
      setPrompts(textsList);
    }, [textsList]);

    const handleInputChange = (i, j, value) => {
      const newPrompts = [...prompts]; // Create a new array
      newPrompts[i * 3 + j] = value;
      setPrompts(newPrompts);
      setTextsList(newPrompts); // Update the textsList in the parent component
    };

  const renderMatrix = () => {
    const matrix = [];
    for (let i = 0; i < 3; i++) {
      const row = [];
      for (let j = 0; j < 3; j++) {
        row.push(
          <div className={styles.inputButtonContainer} key={`${i}-${j}`}>
            <input
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  onRegenerateClicked(i,j)
                }
              }}
              className={styles.smallInput}
              type="text"
              placeholder={`Card ${i * 3 + j + 1} Prompt`}
              value={prompts[i * 3 + j]}
              onChange={(e) => handleInputChange(i, j, e.target.value)}
              disabled={isDisabledList[i * 3 + j]}
            />
            {isAllowedRegenerateList[i * 3 + j] && <button
              onClick={() => onRegenerateClicked(i,j)}
              className={styles.button}
            >
              <Icon name="repeat" />
            </button>}
          </div>
        );
      }
      matrix.push(
        <div key={i} className={styles.row}>
          {row}
        </div>
      );
    }
    return matrix;
  };

  return <div className={styles.matrix}>{renderMatrix()}</div>;
};

export default InputMatrix;
