import { toast } from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const CURRENT_VERSION = process.env.REACT_APP_VERSION; // Add this line to store your current front-end version

let currentAccessToken = localStorage.getItem("AccessToken") || ""; // Holds the current access token

const ApiService = {
  // Set access token and store it in localStorage
  setAccessToken(token) {
    currentAccessToken = token;
    localStorage.setItem("AccessToken", token);
  },

  // Save refresh token in localStorage
  setRefreshToken(token) {
    localStorage.setItem("RefreshToken", token);
  },

  // Refresh the access token using the refresh token
  async refreshToken() {
    const refreshToken = localStorage.getItem("RefreshToken");
    if (!refreshToken) {
      throw new Error("No refresh token available.");
    }

    try {
      const response = await fetch(`${BASE_URL}/refresh`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refreshToken }),
      });

      if (!response.ok) {
        throw new Error("Failed to refresh token.");
      }

      const data = await response.json();
      this.setAccessToken(data.accessToken); // Update the current access token
      return data.accessToken;
    } catch (error) {
      console.error("Failed to refresh token: ", error);
      throw error;
    }
  },

  // Helper method to build headers for requests
  buildHeaders(options) {
    const headers = {
      ...(options?.headers || {}),
      ...(currentAccessToken
        ? { Authorization: `Bearer ${currentAccessToken}` }
        : {}),
    };

    // Only set Content-Type if it's not already set
    if (!headers["Content-Type"] && !(options?.body instanceof FormData)) {
      headers["Content-Type"] = "application/json";
    }

    return headers;
  },

  // Fetch with automatic token refresh if needed
  async fetchWithRefreshToken(endpoint, options) {
    try {
      console.log(`${BASE_URL}${endpoint}`);
      const isFormData = options?.body instanceof FormData;

      let response = await fetch(`${BASE_URL}${endpoint}`, {
        ...options,
        headers: this.buildHeaders(options),
        // Only stringify the body if it's not FormData
        body: isFormData ? options.body : JSON.stringify(options.body),
      });

      // If the token has expired, try refreshing it
      if (response.status === 401) {
        await this.refreshToken();
        // Retry the request with the new token
        response = await fetch(`${BASE_URL}${endpoint}`, {
          ...options,
          headers: this.buildHeaders(options),
          body: isFormData ? options.body : JSON.stringify(options.body),
        });
      }

      // Handle X-VERSION header
      const serverVersion = response.headers.get("X-VERSION");
      if (serverVersion && serverVersion !== CURRENT_VERSION) {
        toast("A new version is available. The page will refresh.", {
          icon: "👏",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000); // Give some time for the toast to be visible
      }

      // Handle response
      if (response.ok) {
        return response.json();
      }

      // Handle errors
      let errorMessage = "Unknown error";
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || errorMessage;
      } catch (err) {
        console.error("Error parsing JSON: ", err);
      }

      throw new Error(errorMessage);
    } catch (error) {
      if (error.message === "Failed to fetch" && error.name === "TypeError") {
        toast.error(
          "Unable to connect to the network. Please check your internet connection and try again."
        );
      } else {
        console.error("Fetch operation failed: ", error);
      }
      throw error; // Ensure the error is propagated
    }
  },
  // Helper methods for common HTTP verbs
  get(endpoint) {
    return this.fetchWithRefreshToken(endpoint, { method: "GET" });
  },

  post(endpoint, body) {
    return this.fetchWithRefreshToken(endpoint, { method: "POST", body: body });
  },

  put(endpoint, body) {
    return this.fetchWithRefreshToken(endpoint, { method: "PUT", body: body });
  },

  delete(endpoint) {
    return this.fetchWithRefreshToken(endpoint, { method: "DELETE" });
  },
};

export default ApiService;
