const mentionStyle = {
  control: {
    fontSize: 14,
    lineHeight: 1.5,
  },
  '&multiLine': {
    control: {
      minHeight: 63,
    },
    highlighter: {
      paddingTop:9,
      paddingLeft: 9,
      fontWeight:400,
      color:"black",
      lineHeight: 1.218,
      color:"transparent",
      overflowY: 'auto',
      background: 'var(--n2)',
      overflowWrap: 'break-word',
    },
    input: {
      padding: 9,
      border: '1px solid silver',
      borderRadius: 12,
      background: 'var(--n2)',
      color: 'var(--n7)',
      '&:focus': {
        borderColor: 'var(--p1)',
        background: 'var(--n)',
      },
      '&::placeholder': {
        color: 'var(--shades1)',
      },
    },
  },
  '&singleLine': {
    control: {
      display: 'inline-block',
      width: 130,
    },
    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },
  suggestions: {
    zIndex: 100,
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: 'purple',
        color: "white"
      },
    },
  },
  assetMention: {
    backgroundColor: 'red',
    borderRadius: '4px',
  },
  characterMention: {
    backgroundColor: 'yellow',

    borderRadius: '4px',
  },
};

export default mentionStyle;
